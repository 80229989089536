import * as React from "react";
import { useParams } from "react-router-dom";
import {
  getBookClub,
  getBookClubPodcasts,
  getRecentBookclubs,
} from "../services/api";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import ClubCard from "../components/ClubCard";
import BookCard from "../components/BookCard";
import TalkCard from "../components/TalkCard";
import Header from "../components/Header";
import { Downloader } from "../components/Downloader";
import dateTimeFormat from "../utils/date-time-format";

export default function BookClub({ inviter = "Your friend" }: any) {
  const book = {
    src: "https://source.unsplash.com/M3xDlTUuix4",
    rating: 2.5, // 0.5 to 4.5
  };

  let { bookClubId } = useParams();
  const [bookClub, setBookClub] = React.useState(null);
  const [podcasts, setPodcasts] = React.useState(null);
  const [otherBookclubs, setOtherBookclubs] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  //   const [bookclubs, setBookclubs] = React.useState(null);
  React.useEffect(() => {
    ReactGA.initialize("G-WV2BKR9G68");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    getBookClub(bookClubId ?? "").then(
      ({
        _id,
        bookClubName,
        owner: {
          userInfo: { firstName, lastName, userName, userProfileUrl },
        },
        bookClubCoverColor,
        description,
        imageUrl,
        members,
        coAdmins,
        books,
      }) => {
        const coverColor = bookClubCoverColor && bookClubCoverColor.split(',');
        setBookClub({
          _id,
          bookClubName,
          host: firstName + " " + lastName,
          imageUrl,
          members: 1 + members.length + coAdmins.length,
          description,
          books: books.map(({ book }: any) => book),
          action: "Join Club",
          background: coverColor ? `rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})` : 'rgb(255 237 213)',
          memberProfiles: [{ firstName, lastName, userName, userProfileUrl },
          ...coAdmins.map(
            ({ userInfo }: any) => userInfo
          ),
          ...members.map(
            ({ userInfo }: any) => userInfo
          ),],
          location: "Vancouver",
        });
      });
    getBookClubPodcasts(bookClubId ?? '', 1).then((podcastData) => {
      console.log(podcastData.book);
      setPodcasts(
        podcastData.map(
          ({
            book: { title, author, imageUrl, _id: bookId, bookCoverColor },
            participants,
            speakers,
            coAdmins,
            bookSessions, _id
          }: any) => {
            const coverColor = bookCoverColor && bookCoverColor.split(',');
            return ({
              _id,
              book: {
                title,
                author,
                imageUrl, _id: bookId
              },
              speakers: speakers.length + coAdmins.length + 1,
              listeners: participants.length,
              bookSessions,
              background: coverColor ? `rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})` : 'rgb(255 237 213)',
              dateTime: dateTimeFormat(bookSessions[0].startDate),
              action: "Join Now",
            })
          }
        )
      );
    });
    getRecentBookclubs().then((bookclubData) => {
      setOtherBookclubs(
        bookclubData
          .filter(({ _id }) => _id != bookClubId)
          .map(
            ({
              _id,
              bookClubName,
              owner: {
                userInfo: { firstName, lastName, userName, userProfileUrl },
              },
              bookClubCoverColor,
              description,
              imageUrl,
              members,
              coAdmins,
              books,
            }: any) => {
              const coverColor = bookClubCoverColor && bookClubCoverColor.split(',');
              return ({
                _id,
                bookClubName,
                host: firstName + " " + lastName,
                imageUrl,
                members: 1 + members.length + coAdmins.length,
                description,
                books: books.map(({ book }: any) => book),
                action: "Join Club",
                background: coverColor ? `rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})` : 'rgb(255 237 213)',
                memberProfiles: [
                  { firstName, lastName, userName, userProfileUrl },
                  ...coAdmins.map(
                    ({ userInfo }: any) => userInfo
                  ),
                  ...members.map(
                    ({ userInfo }: any) => userInfo
                  ),
                ],
                location: "Vancouver",
              })
            }
          )
      );
    });
  }, []);
  return <div className="relative min-h-screen">
    <Header setModal={setModal} modal={modal} />
    <Downloader setModal={setModal} modal={modal} />
    <div className="flex flex-col pt-12 pb-52 items-center">
      <div className="flex flex-col items-center justify-items-center gap-6 sm:gap-8 px-4 py-6 sm:px-12 sm:py-8 max-w-7xl font-body text-body-1 text-white">
        <div className="flex flex-col items-center gap-4 sm:gap-6 pt-0 sm:pt-0 p-5 sm:p-11">
          {bookClub && (
            <div className="flex flex-col items-center justify-center sm:text-title-2">
              <p className="w-2/3 text-center p-4">
                Join this club on{" "}
                <strong>Moniro</strong>
              </p>
              <ClubCard club={bookClub} big onClick={() => setModal(!modal)} />
            </div>
          )}
          {podcasts && podcasts.length > 0 && (
            <div className="flex flex-col items-center">
              <p className="text-body-2 sm:text-body-1 pb-4 sm:pb-6">
                Club Events
              </p>
              <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8">
                {podcasts.map((podcast: any) => (
                  <TalkCard talk={podcast} book={podcast.book} />
                ))}
              </div>
              {/* <a
                href=""
                className="underline mt-4 text-small-1 sm:text-body-2 font-light"
              >
                View more
              </a> */}
            </div>
          )}
          {bookClub && bookClub.books.length > 0 &&
            <div className="flex flex-col items-center">
              <p className="text-body-2 sm:text-body-1 pb-4 sm:pb-6">
                Club Books
              </p>
              <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8">
                {bookClub.books.map((book) => (
                  <a
                    href={`${location.protocol}//${location.host}/book/${book._id}`}
                  >
                    <BookCard book={book} />
                  </a>
                ))}
              </div>
              {/* <a
            href=""
            className="underline mt-4 text-small-1 sm:text-body-2 font-light"
          >
            View more
          </a> */}
            </div>}
        </div>
        <div className="h-0.5 sm:h-1 bg-gray-400 bg-opacity-75 rounded-full w-1/4"></div>
        {otherBookclubs && otherBookclubs.length > 0 && <div className="flex flex-col items-center mt-5 sm:mt-8">
          <p className="text-body-2 sm:font-black sm:text-title-2 sm:font-title pb-4 sm:pb-6">
            Other <strong>Clubs</strong>
          </p>
          <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8">
            {otherBookclubs.map((bookClub: any) => <a href={`${location.protocol}//${location.host}/bookclub/${bookClub._id}`}><ClubCard club={bookClub} /></a>)}
          </div>
          {/* <a
          href=""
          className="underline mt-4 text-body-2 sm:text-body-1 font-light"
        >
          View more
        </a> */}
        </div>
        }
      </div>
    </div>
    <Footer />
  </div>
    ;
}
