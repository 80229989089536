import {
  get,
  getDatabase,
  onDisconnect,
  onValue,
  ref,
  remove,
  set,
} from "firebase/database";
import {
  ConnectionState,
  RemoteAudioTrack,
  RemoteVideoTrack,
  useRTCClient,
  useRemoteAudioTracks,
  useRemoteUsers,
  useRemoteVideoTracks,
} from "agora-rtc-react";
import { useNavigate, useParams, } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Book from "../components/BookCard";
import React, { useEffect, useState } from "react";
import { getBook, getPodcast, getRTCToken } from "../services/api";
import { Downloader } from "../components/Downloader";
import dateTimeFormat from "../utils/date-time-format";

export default function LiveRoom() {
  let { sessionId, podcastId } = useParams<string>();
  const navigate = useNavigate();
  const remoteUsers = useRemoteUsers();
  const { audioTracks } = useRemoteAudioTracks(remoteUsers);
  const { videoTracks } = useRemoteVideoTracks(remoteUsers);
  const [admins, setAdmins] = React.useState([]);
  const [broadcast, setBroadcast] = React.useState(null);
  const [participants, setParticipants] = React.useState([]);
  const [speakers, setSpeakers] = React.useState([]);
  const [guests, setGuests] = React.useState([]);
  const [podcast, setPodcast] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [play, setPlay] = React.useState(false);
  const [theUid, setUid] = useState(null);
  let agoraClient = useRTCClient();
  let theeeuid;
  React.useEffect(() => {
    if(!theeeuid){const uid = Math.floor(Math.random() * 100000000);
      theeeuid=uid;
    setUid(uid);
    getRTCToken(uid.toString(), sessionId).then((token) => {
      if (token.rtcToken && agoraClient.connectionState === "DISCONNECTED") {
        agoraClient
          .join(
            "b1ffe4ede7f242bda1ce9c29fb44a8b6",
            sessionId,
            token.rtcToken,
            uid
          )
          .then(() => {
            setPlay(!play);
          });
        const userListRef = ref(
          database,
          "rtc/channel/" + sessionId + "/userlist/guest/" + uid
        );
        set(userListRef, uid);
        onDisconnect(userListRef).remove();
      }
    });
    getPodcast(podcastId ?? "").then(
      ({
        circle: { book, participants, speakers, coAdmins, bookSessions, _id },
      }) => {
        // console.log("CIRCLE:", coAdmins);
        getBook(book).then(({ book }) => {
          const {
            title,
            description,
            author,
            imageUrl,
            avgRating,
            _id: bookId,
            bookCoverColor,
          }: any = book[0];
          const coverColor = bookCoverColor && bookCoverColor.split(",");
          setPodcast({
            _id,
            book: {
              title,
              author,
              imageUrl,
              _id: bookId,
            },
            speakers: speakers.length + coAdmins.length + 1,
            description,
            listeners: participants.length,
            bookSessions,
            rating: avgRating,
            background: coverColor ? `rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})` : 'rgb(255 237 213)',
            dateTime: dateTimeFormat(bookSessions[0].startDate),
            action: "Join Now",
          });
        });
        // getBookRecording(book, 1).then((recordingsData) => {
        //   // console.log(recordingsData);
        //   setRecordings(recordingsData[0]?.recordings ?? []);
        // });
      }
    );
    const database = getDatabase();
    const userListRef = ref(database, "rtc/channel/" + sessionId + "/userlist");
    const broadcastRef = ref(
      database,
      "rtc/channel/" + sessionId + "/broadcast"
    );
    onValue(broadcastRef, async (snapshot) => {
      const data = snapshot.val();
      setBroadcast(data);
    });
    onValue(userListRef, async (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        navigate(`/booktalk/${podcastId}`);
      } else {
        const ladmins = data.admin ? Object.keys(data.admin) : [];
        if (ladmins.length < 1) {
          remove(
            ref(database, "rtc/channel/" + sessionId + "/userlist/guest/" + uid)
          );
          navigate(`/booktalk/${podcastId}`);
        }
        const lparticipants = data.participant
          ? Object.keys(data.participant)
          : [];
        const lspeakers = data.speaker ? Object.keys(data.speaker) : [];
        const lguests = data.guest ? Object.keys(data.guest) : [];
        setTimeout(async () => {
          const theAdmins = await Promise.all(
            ladmins.map((admin) =>
              get(
                ref(database, "rtc/channel/" + sessionId + "/userdata/" + admin)
              ).then((snapshot) => snapshot.val())
            )
          );
          setAdmins(theAdmins);
          const theParticipants = await Promise.all(
            lparticipants.map((participant) =>
              get(
                ref(
                  database,
                  "rtc/channel/" + sessionId + "/userdata/" + participant
                )
              ).then((snapshot) => snapshot.val())
            )
          );
          setParticipants(theParticipants);
          const theSpeakers = await Promise.all(
            lspeakers.map((speaker) =>
              get(
                ref(
                  database,
                  "rtc/channel/" + sessionId + "/userdata/" + speaker
                )
              ).then((snapshot) => snapshot.val())
            )
          );
          setSpeakers(theSpeakers);
          setGuests(lguests);
        }, 2000);
      }
    });}
    return ()=>{
    const database = getDatabase();
    const userListRef = ref(database, "rtc/channel/" + sessionId + "/userlist/guest/" + theeeuid);
    remove(userListRef);}
  }, []);
  // audioTracks.map(track => track.play());
  return (
    <>
      {/* {audioTracks &&
        audioTracks.map((track) => (
          <RemoteAudioTrack key={track.getUserId()} play track={track} />
        ))}
        {videoTracks&&videoTracks.map((track)=>
        <RemoteVideoTrack play track={track} />
        )} */}
      <div className="relative min-h-screen">
        <Header setModal={setModal} modal={modal} />
        <Downloader setModal={setModal} modal={modal} />
        <div className=" pt-16 pb-52">
          <div className="flex flex-col gap-6 sm:gap-8 items-center lg:gap-12 px-4 sm:px-6 lg:px-8 py-8 sm:py-10 lg:py-12 text-white font-body">
            <div className="flex flex-row gap-4 sm:gap-6 lg:gap-8">
              <div
                className={
                  `${videoTracks.length > 0 && "sm:w-2/3"}` +
                  " flex flex-col gap-8 sm:gap-12 lg:gap-16 items-center"
                }
              >
                {podcast && (
                  <div className="flex flex-row w-full sm:w-fit sm:place-content-center items-center gap-8">
                    <div className="hidden sm:inline">
                      {videoTracks.length < 1 && (
                        <a
                          href={`${location.protocol}//${location.host}/book/${podcast.book._id}`}
                        >
                          <Book book={podcast.book} big />
                        </a>
                      )}
                    </div>
                    <div className="hidden xl:inline">
                      {videoTracks.length > 0 && (
                        <a
                          href={`${location.protocol}//${location.host}/book/${podcast.book._id}`}
                        >
                          <Book book={podcast.book} big />
                        </a>
                      )}
                    </div>
                    <div className="flex flex-col sm:pb-16 w-full">
                      <div className="mb-2 sm:mb-3 lg:mb-4 w-full border-b text-body-2 sm:text-body-1 border-white">
                        <p>Live Podcast on</p>
                      </div>
                      <p className="sm:font-title sm:font-black text-body-1 sm:text-title-1">
                        {podcast.book.title}
                      </p>
                      <p className="text-body-2 sm:text-body-1 font-extralight">
                        By {podcast.book.author}
                      </p>
                      <br />
                      <br />
                      <div
                        onClick={() => {
                          setPlay(!play);
                        }}
                        className="flex p-1 rounded-full place-content-center border box-content border-solid hover:sm:bg-gradient1 hover:border-gradient1 border-gray-200 w-fit"
                      >
                        {play ? (
                          <span className="icon-[mdi--speakerphone] drop-shadow-card w-6 h-6 sm:w-8 sm:h-8" />
                        ) : (
                          <span className="icon-[mdi--speakerphone-off] drop-shadow-card w-6 h-6 sm:w-8 sm:h-8" />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {audioTracks &&
                  audioTracks.map((track) => (
                    <RemoteAudioTrack
                      key={track.getUserId()}
                      play={play}
                      track={track}
                    />
                  ))}
                <div className="flex flex-col xl:flex-row p-4 sm:p-6 lg:p-8 gap-4 sm:gap-8">
                  <div className="flex flex-row gap-4 sm:gap-8 lg:gap-12">
                    <div className="flex flex-col">
                      <p className="p-1 sm:p-2 pb-2 sm:pb-4 text-body-2 sm:text-body-1">
                        Speaking
                      </p>
                      <div className="flex flex-row flex-wrap gap-4 sm:gap-6 lg:gap-8">
                        {[...admins, ...speakers]
                          .slice(
                            0,
                            [...admins, ...speakers].length > 5
                              ? 5
                              : [...admins, ...speakers].length + 1
                          )
                          .filter((u) => u)
                          .map(({ userProfileUrl, name }, i) => (
                            <div
                              key={i}
                              className="w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 bg-cover bg-center drop-shadow-sm rounded-full items-center flex justify-center font-bold"
                              style={{
                                backgroundImage: userProfileUrl
                                  ? `url(${userProfileUrl})`
                                  : undefined,
                                backgroundColor: !userProfileUrl
                                  ? "rgb(3 169 244 / 50%)"
                                  : undefined,
                              }}
                            >
                              {!userProfileUrl && name && name[0].toUpperCase()}
                            </div>
                          ))}
                        {[...admins, ...speakers].length > 5 && (
                          <div className="relative w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24">
                            <div
                              className="absolute inset-0 w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 blur-sm bg-cover bg-center drop-shadow-sm rounded-full"
                              style={{
                                backgroundImage: `url(${
                                  [...admins, ...speakers][5]
                                })`,
                              }}
                            ></div>
                            <div className="absolute inset-0 w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 font-body text-body-2 sm:text-body-1 lg:text-title-2 text-white font-medium flex items-center justify-center">
                              +{[...admins, ...speakers].length - 5}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {videoTracks.length > 0 &&
                    window.innerWidth <= 640 &&
                    broadcast ? (
                      <div className="backdrop-blur-xl">
                        <div className="w-[128px] h-[228px] md:w-[196px] md:h-[348px] lg:w-[256px] lg:h-[480px] sm:hidden bg-cover bg-center rounded-lg drop-shadow-book">
                          {<RemoteVideoTrack play track={videoTracks[0]} />}
                        </div>
                      </div>
                    ) : (
                      podcast && (
                        <div className="sm:hidden">
                          <a
                            href={`${location.protocol}//${location.host}/book/${podcast.book._id}`}
                          >
                            <Book book={podcast.book} big />
                          </a>
                        </div>
                      )
                    )}
                    <div className="hidden sm:flex flex-col">
                      <p className="p-1 sm:p-2 pb-2 sm:pb-4 text-body-2 sm:text-body-1">
                        Listening
                        {/* -{participants.length}-{remoteUsers.length} - {admins.length} - {speakers.length} - 1 */}
                      </p>
                      <div className="flex flex-row flex-wrap gap-4 sm:gap-6 lg:gap-8">
                        {participants
                          .slice(
                            0,
                            participants.length > 5
                              ? 5
                              : participants.length + 1
                          )
                          .filter((u) => u)
                          .map(({ userProfileUrl, name }: any, i) => (
                            <div
                              key={i}
                              className="w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 bg-cover bg-center drop-shadow-sm rounded-full items-center flex justify-center text-title-1 font-bold"
                              style={{
                                backgroundImage: userProfileUrl
                                  ? `url(${userProfileUrl})`
                                  : undefined,
                                backgroundColor: !userProfileUrl
                                  ? "rgb(3 169 244 / 50%)"
                                  : undefined,
                              }}
                            >
                              {!userProfileUrl && name && name[0].toUpperCase()}
                            </div>
                          ))}
                        {participants.length > 5 ? (
                          <div className="relative w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24">
                            <div
                              className="absolute inset-0 w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 blur-sm bg-cover bg-center drop-shadow-sm rounded-full"
                              style={{
                                backgroundImage: `url(${participants[5]})`,
                              }}
                            ></div>
                            <div className="absolute inset-0 w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 font-body text-body-2 sm:text-body-1 lg:text-title-2 text-white items-center flex justify-center font-bold">
                              +{participants.length - 5} & GU: {guests.length}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 bg-cover bg-center drop-shadow-sm rounded-full items-center flex justify-center font-bold"
                            style={{ backgroundColor: "rgb(3 169 244 / 50%)" }}
                          >
                            GU: {guests.length}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex sm:hidden flex-col">
                    <p className="p-1 sm:p-2 pb-2 sm:pb-4 text-body-2 sm:text-body-1">
                      Listening
                    </p>
                    <div className="flex flex-row flex-wrap gap-4 sm:gap-6 lg:gap-8">
                      {participants
                        .slice(
                          0,
                          participants.length > 5 ? 5 : participants.length + 1
                        )
                        .filter((u) => u)
                        .map(({ userProfileUrl, name }, i) => (
                          <div
                            key={i}
                            className="w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 bg-cover bg-center drop-shadow-sm rounded-full items-center flex justify-center font-bold"
                            style={{
                              backgroundImage: userProfileUrl
                                ? `url(${userProfileUrl})`
                                : undefined,
                              backgroundColor: !userProfileUrl
                                ? "rgb(3 169 244 / 50%)"
                                : undefined,
                            }}
                          >
                            {!userProfileUrl && name && name[0].toUpperCase()}
                          </div>
                        ))}
                      {participants.length > 5 ? (
                        <div className="relative w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24">
                          <div
                            className="absolute inset-0 w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 blur-sm bg-cover bg-center drop-shadow-sm rounded-full"
                            style={{
                              backgroundImage: `url(${participants[5]})`,
                            }}
                          ></div>
                          <div className="absolute inset-0 w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 font-body text-body-2 lg:text-title-2 text-white items-center flex justify-center font-bold">
                            +{participants.length - 5} & GU: {guests.length}
                          </div>
                        </div>
                      ) : (
                        <div
                          className="w-14 h-14 sm:w-16 sm:h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24 bg-cover bg-center drop-shadow-sm rounded-full items-center flex justify-center font-bold"
                          style={{ backgroundColor: "rgb(3 169 244 / 50%)" }}
                        >
                          GU: {guests.length}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {videoTracks.length > 0 &&
                window.innerWidth > 639 &&
                broadcast && (
                  <div className="hidden sm:inline w-[280px] h-[580px] xl:w-[360px] xl:h-[840px] bg-cover bg-center rounded-xl drop-shadow-book border border-gradient2">
                    {<RemoteVideoTrack play track={videoTracks[0]} />}
                  </div>
                )}
            </div>
            <div
              onClick={() => 
                navigate(`/booktalk/${podcastId}`)}
            >
              <div className="flex flex-row gap-2 sm:gap-3 xl:gap-4 px-5 sm:px-6 xl:px-8 py-2 sm:py-3 xl:py-4 bg-rose-500 items-center rounded-full">
                <span className="icon-[material-symbols--call-end] w-6 h-6 sm:w-8 sm:h-8"></span>
                <p className="text-body-2 sm:text-body-1 xl:text-title-2 font-medium">
                  Leave
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
