import { useNavigate } from "react-router-dom";
import tinycolor from "tinycolor2";
function Club({ club, big, onClick = () => { } }: any) {
  const membersNubmer = club.memberProfiles.length - 1;
  const color = tinycolor(club.background).isDark();
  const navigate = useNavigate();
  if (big) {
    return (
      <div onClick={()=>{navigate(`/bookclub/${club._id}`);}}
        className="w-72 sm:w-[440px] h-fit drop-shadow-card p-4 rounded-2xl flex flex-col gap-4" style={{ backgroundColor: club.background }}
      >
        <div className="items-center flex flex-row aligh-start gap-3 sm:gap-4 ">
          <div
            className="w-20 h-32 sm:w-36 sm:h-52 bg-cover bg-center rounded-lg drop-shadow-book"
            style={{ backgroundImage: `url(${club.imageUrl})` }}
          ></div>
          <div className="flex flex-col my-1 gap-1">
            {/* <div className="flex items-center gap-1">
              <span className="icon-[material-symbols--distance] w-4 h-4 sm:w-5 sm:h-5"></span>
              <p className="font-body text-small-1 sm:text-body-2">
                {club.location}
              </p>
            </div> */}
            <h1 className={"font-body font-medium sm:font-title uppercase text-body-1 text-ellipsis line-clamp-2 sm:text-title-2 sm:font-bold max-w-44 sm:max-w-60 mb-1 " + (color ? "text-white" : "text-slate-900")}>
              {club.bookClubName}
            </h1>
            <div className="flex items-center gap-3 sm:hidden">
              <div
                className="w-10 h-10 bg-cover bg-center rounded-full"
                style={{ backgroundImage: `url(${club.memberProfiles[0]})` }}
              ></div>
              <div>
                <p className="font-body text-body-2 text-ellipsis line-clamp-1 max-w-28 text-gray-900">
                  {club.host}
                </p>
                <p className="font-body text-small-1 text-gray-500">Host</p>
              </div>
            </div>
            <p className={"hidden max-w-60 max-h-28 sm:block font-body text-justify text-body-2 text-ellipsis line-clamp-5 overflow-auto " + (color ? "text-white" : "text-slate-900")}>
              {club.description}
            </p>
          </div>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="sm:flex items-center gap-2 hidden">
            <div
              className="w-12 h-12 bg-cover bg-center rounded-full flex items-center justify-center"
              style={{ backgroundImage: club.memberProfiles[0].userProfileUrl ? `url(${club.memberProfiles[0].userProfileUrl})` : undefined, backgroundColor: !club.memberProfiles[0].userProfileUrl ? 'rgb(3 169 244 / 50%)' : undefined }}
            >{!club.memberProfiles[0].userProfileUrl && club.memberProfiles[0].firstName && club.memberProfiles[0].firstName[0].toUpperCase()}</div>
            <div>
              <p className={"font-body text-body-2 max-w-32 text-ellipsis line-clamp-1 " + (color ? "text-white" : "text-slate-900")}>
                {club.host}
              </p>
              <p className="font-body text-small-1 text-gray-500">Host</p>
            </div>
          </div>
          <div className="w-full sm:w-auto flex justify-between sm:justify-normal sm:gap-12 items-center">
            <div className="flex flex-row">
              {club.memberProfiles.slice(1, membersNubmer > 4 ? 5 : membersNubmer + 1).map(({ userProfileUrl, firstName }: any, i) => (
                <div key={i}
                  className="w-7 h-7 -ml-1.5 bg-cover bg-center drop-shadow-sm rounded-full flex items-center justify-center"
                  style={{ backgroundImage: userProfileUrl ? `url(${userProfileUrl})` : undefined, backgroundColor: !userProfileUrl ? 'rgb(3 169 244 / 50%)' : undefined }}
                >{!userProfileUrl && firstName && firstName[0].toUpperCase()}</div>
              ))}
              {membersNubmer > 4 && <div className="relative">
                <div
                  className="absolute inset-0 w-7 h-7 -ml-1.5 blur-sm bg-cover bg-center drop-shadow-sm rounded-full flex items-center justify-center"
                  style={{
                    backgroundImage: club.memberProfiles[5].userProfileUrl ? `url(${club.memberProfiles[5].userProfileUrl})` : undefined, backgroundColor: !club.memberProfiles[5].userProfileUrl ? 'rgb(3 169 244 / 50%)' : undefined
                  }}
                >{!club.memberProfiles[5].userProfileUrl && club.memberProfiles[5].firstName && club.memberProfiles[5].firstName[0].toUpperCase()}</div>
                <div className="absolute inset-0 w-7 h-7 -ml-1.5 font-body text-body-2 text-white font-medium flex items-center justify-center">
                  +{membersNubmer - 4}
                </div>
              </div>}
            </div>
            <div className="grid w-fit justify-items-end">
              <span className={"icon-[material-symbols--group-rounded] w-4 h-4 sm:w-5 sm:h-5 " + (color ? "text-white" : "text-slate-900")}></span>
              <p className={"font-body text-small-1 sm:text-body-2 " + (color ? "text-white" : "text-slate-900")}>
                {club.members} Members
              </p>
            </div>
          </div>
        </div>
        <div>
          <p className={"sm:hidden max-w-64 max-h-20 block font-body text-justify text-ellipsis line-clamp-5 text-small-1 overflow-auto " + (color ? "text-white" : "text-slate-900")}>
            {club.description}
          </p>
        </div>
        <div className="flex w-fill font-body text-body-2 sm:text-body-1 font-medium sm:font-normal text-white">
          <button onClick={(e)=>{e.stopPropagation();onClick();}} className="h-8 sm:h-10 w-full m-auto bg-gradient-to-r from-gradient1 to-gradient2 hover:bg-gradient-to-r hover:from-gray-800 hover:to-blue-600  focus:outline-none rounded-full">
            {club.action}
          </button>
        </div>
      </div>
    );
  }
  return (
    <div
      className="w-48 sm:w-64 h-28 sm:h-36 drop-shadow-card p-2 sm:p-3 rounded-xl sm:rounded-2xl" style={{ backgroundColor: club.background }}
    >
      <div className="items-center flex flex-row aligh-start  gap-3 sm:gap-4 ">
        <div
          className="w-11 h-16 sm:w-16 sm:h-24 bg-cover bg-center rounded-lg drop-shadow-book"
          style={{ backgroundImage: `url(${club.imageUrl})` }}
        ></div>
        <div className="my-1">
          <h1 className={"font-body text-body-2 sm:text-body-1 max-w-28 sm:max-w-40 h-12 sm:h-16 text-ellipsis line-clamp-2 mb-1 " + (color ? "text-white" : "text-slate-900")}>
            {club.bookClubName}
          </h1>
          {/* <div className="flex items-center gap-1">
            <span className="icon-[material-symbols--distance] w-4 h-4 sm:w-5 sm:h-5"></span>
            <p className="font-body text-small-1 sm:text-body-2">
              {club.location}
            </p>
          </div> */}
          <div className="flex items-center gap-1">
            <span className={"icon-[material-symbols--group-rounded] w-4 h-4 sm:w-5 sm:h-5 " + (color ? "text-white" : "text-slate-900")}></span>
            <p className={"font-body text-small-1 sm:text-body-2 " + (color ? "text-white" : "text-slate-900")}>
              {club.members} Members
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Club;
