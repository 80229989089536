import * as React from "react";
import { useParams } from "react-router-dom";
import {
  getBook,
  getBookRecording,
  getPodcast,
  getRecentPodcasts,
} from "../services/api";
import ReactGA from "react-ga4";
import TalkCard from "../components/TalkCard";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Downloader } from "../components/Downloader";
import dateTimeFormat from "../utils/date-time-format";

export default function Podcast({ inviter = "Your friend" }: any) {
  let { podcastId } = useParams();
  const [modal, setModal] = React.useState(false);
  const [podcast, setPodcast] = React.useState(null);
  const [recordings, setRecordings] = React.useState([]);
  const [recPlayUrl, setRecPlayUrl] = React.useState("");
  const [otherPodcasts, setOtherPodcasts] = React.useState(null);
  //   const [bookclubs, setBookclubs] = React.useState(null);
  React.useEffect(() => {
    ReactGA.initialize("G-WV2BKR9G68");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });

    // getRecentPodcasts().then((podcastData) => {
    //   setPodcasts(podcastData);
    // });
    getPodcast(podcastId ?? "").then(
      ({ circle: { book, participants, speakers, coAdmins, bookSessions, _id } }) => {
        // console.log("CIRCLE:", coAdmins);
        getBook(book).then(({ book }) => {
          const { title, description, author, imageUrl, avgRating, _id: bookId, bookCoverColor}: any =
            book[0];
            const coverColor= bookCoverColor&& bookCoverColor.split(',');
          setPodcast({
            _id,
            book: {
              title,
              author,
              imageUrl, _id: bookId
            },
            speakers: speakers.length + coAdmins.length + 1,
            description,
            listeners: participants.length,
            bookSessions,
            rating: avgRating,
            background: coverColor?`rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})`:'rgb(255 237 213)',
            dateTime: dateTimeFormat(bookSessions[0].startDate),
            action: "Join Now",
          });
        });
        // getBookRecording(book, 1).then((recordingsData) => {
        //   // console.log(recordingsData);
        //   setRecordings(recordingsData[0]?.recordings ?? []);
        // });
      }
    );
    getRecentPodcasts().then((podcastData) => {
      // console.log("podcastData", podcastData);
      setOtherPodcasts(
        podcastData
          .filter(({ _id }) => _id != podcastId)
          .map(
            ({
              book: { title, description, author, imageUrl, _id: bookId, bookCoverColor },
              participants,
              speakers,
              bookSessions,
              _id,
            }: any) => {
              const coverColor= bookCoverColor&& bookCoverColor.split(',');
              return ({
              _id,
              book: {
                title,
                author,
                imageUrl, _id: bookId
              },
              speakers: speakers.length,
              description,
              listeners: participants.length,
              bookSessions,
              background: coverColor?`rgb(${coverColor[0]},${coverColor[1]},${coverColor[2]})`:'rgb(255 237 213)',
              dateTime: dateTimeFormat(bookSessions[0].startDate),
              action: "Join Now",
            })}
          )
      );
      // sttt();
    });
  }, []);
  return (
    <>
      {podcast && (
        <div className="relative min-h-screen">
          <Header setModal={setModal} modal={modal} />
          <Downloader setModal={setModal} modal={modal} />
          <div className="flex justify-center pt-12 pb-52">
            <div className="flex flex-col items-center justify-items-center gap-6 sm:gap-8 px-4 py-6 sm:px-12 sm:py-8 font-body text-body-1 text-white">
              <div className="flex flex-col items-center gap-4 sm:gap-6 pt-0 sm:pt-0 p-5 sm:p-11">
                <div className="flex flex-col items-center justify-center sm:text-title-2">
                  <p className="w-2/3 text-center p-4">
                    Join this podcast on{" "}
                    <strong>Moniro</strong>
                  </p>
                  <TalkCard talk={podcast} book={podcast.book} big onClick={()=>setModal(!modal)} />
                </div>
                {recordings &&
                  recordings.map((recording, index) => (
                    <div className="flex w-fill">
                      <button
                        onClick={() => {
                          setRecPlayUrl(recording.recordingUrl);
                          setModal(!modal);
                          // const uid = Math.floor(Math.random() * 100000000);
                          // getRTCToken(uid.toString(),talkAction).then((token)=>{
                          //   if(token.rtcToken){localStorage.setItem('rtcToken',token.rtcToken);
                          //   localStorage.setItem('uid',uid.toString());
                          //   navigate('/booktalk/'+talk._id+'/live/'+talkAction);}
                          // });
                        }}
                        className="h-8 sm:h-10 w-full m-auto bg-gradient-to-r from-gradient1 to-gradient2 hover:bg-gradient-to-r hover:from-gray-800 hover:to-blue-600  focus:outline-none text-white text-body-2 sm:text-body-1 font-medium sm:font-normal rounded-full"
                      >
                        Play recording {index + 1}
                      </button>
                    </div>
                  ))}
                {recPlayUrl && (
                  <div
                    className={"relative z-10 " + (recPlayUrl ? "" : "hidden")}
                    aria-labelledby="modal-title"
                    role="dialog"
                    aria-modal="true"
                    onClick={() => {
                      setModal(!recPlayUrl);
                    }}
                  >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="relative flex transform overflow-hidden rounded-lg justify-center bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                        >
                          <video id="video1" width="420" controls>
                            <source src={recPlayUrl} type="video/mp4" />
                            {/* <source src="mov_bbb.ogg" type="video/ogg" /> */}
                            Your browser does not support HTML video.
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {podcast.description&&podcast.description!=='null'&&<div className="flex flex-col text-small-1 sm:text-body-1 gap-1 sm:gap-2 text-center">
                  <p className="sm:text-body-1">
                    About <strong>{podcast.title}</strong>
                  </p>
                  <p className="sm:text-body-1 whitespace-pre-line line-clamp-[10] max-w-prose overflow-auto">
                    {podcast.description}
                  </p>
                </div>}
              </div>
              <div className="h-0.5 sm:h-1 bg-gray-400 bg-opacity-75 rounded-full w-1/4"></div>
              {otherPodcasts && otherPodcasts.length > 0 && (
                <div className="flex flex-col items-center mt-5 sm:mt-8">
                  <p className="text-body-2 sm:font-bold sm:text-title-2 sm:font-title uppercase pb-4 sm:pb-6">
                    Check out other <strong>Podcasts</strong>
                  </p>
                  <div className="flex flex-row flex-wrap justify-center gap-8">
                    {otherPodcasts.map((podcast) => (
                      <a
                        key={podcast._id}
                        href={`${location.protocol}//${location.host}/booktalk/${podcast._id}`}
                      >
                        <TalkCard talk={podcast} book={podcast.book} />
                      </a>
                    ))}
                  </div>
                  {/* <a
                    href=""
                    className="underline mt-4 text-small-1 sm:text-body-2 font-light"
                  >
                    View more
                  </a> */}
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}
