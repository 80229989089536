import React from 'react';
import { Box, Typography, Container, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import imgFeatureRead from '../../assets/images/Features - Next read.png';
import imgFeaturedClub from '../../assets/images/Features - Clubs.png';
import imgFeatureFriends from '../../assets/images/Features - Make friends.png';
import imgFeatureInfluancer from '../../assets/images/Features - Influencer.png';

const data = [
  {
    title: 'Next Read',
    description: 'Get a personalized pick \n for your next read',
    image: imgFeatureRead,
  },
  {
    title: 'Books they read',
    description: 'Discover the bookshelf of \n highly successful individuals',
    image: imgFeatureInfluancer,
  },
  {
    title: 'Clubs',
    description: 'Make reading fun. \n Do it with friends',
    image: imgFeaturedClub,
  },
  {
    title: 'Make new friends',
    description: 'Find your Book mates. \n Cherish your shared interest',
    image: imgFeatureFriends,
  },
];

const Features = () => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container sx={{ padding: 4, paddingTop: 10, width: '100vw', color: 'white', backgroundColor: 'rgba(5, 36, 51, 1)' }}>
      <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 7 }}>
        <Typography sx={{ fontFamily: 'Raleway', fontSize: '48px', fontWeight: 600 }}>More Features</Typography>
        <br />
        <Typography sx={{ fontFamily: 'Poppins', fontSize: '34px', marginTop: '-25px', fontWeight: 500 }}>loved by users</Typography>
      </Typography>
      <Grid container spacing={4}>
        {isMobile ? (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {data.map((item, index) => (
                <Box key={index} sx={{ textAlign: 'center', marginBottom: 4 }}>
                  <Box
                    component="img"
                    src={item.image}
                    alt={`${item.title} Image`}
                    sx={{
                      width: 295.06,
                      height: 403.53,
                      opacity: 1,
                      border: '1px solid transparent',
                      borderRadius: 2,
                      marginBottom: 2,
                      marginTop: 2,
                    }}
                  />
                  <Typography sx={{ fontFamily: 'Raleway', fontSize: '25px', fontWeight: 600 }}>{item.title}</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 1 }}>
                    <Box
                      sx={{
                        backgroundColor: 'rgba(104, 176, 227, 1)',
                        width: 6,
                        height: 60,
                        borderRadius: 5,
                        marginRight: 2,
                      }}
                    />
                    <Typography
                      sx={{
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontWeight: 500,
                        lineHeight: '30px',
                        whiteSpace: 'pre-line',
                        textAlign: 'center',
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        ) : isTabletOrMobile ? (
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {data.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                    justifyContent: 'center',
                    width: '100%',
                    marginBottom: 4,
                  }}
                >
                  <Box
                    component="img"
                    src={item.image}
                    alt={`${item.title} Image`}
                    sx={{
                      width: 295.06,
                      height: 403.53,
                      opacity: 1,
                      border: '1px solid transparent',
                      borderRadius: 2,
                      margin: 2,
                    }}
                  />
                  <Box sx={{ flex: 1, textAlign: 'center', marginLeft: index % 2 === 0 ? 4 : 0, marginRight: index % 2 === 0 ? 0 : 4 }}>
                    <Typography sx={{ fontFamily: 'Raleway', fontSize: '25px', fontWeight: 600 }} gutterBottom>
                      {item.title}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Box
                        sx={{
                          backgroundColor: 'rgba(104, 176, 227, 1)',
                          width: 6,
                          height: 60,
                          opacity: 1,
                          borderRadius: 5,
                          marginX: 2,
                        }}
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '30px',
                          whiteSpace: 'pre-line',
                          textAlign: 'left',
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: 8 }}>
                {data.filter((_, i) => i % 2 === 0).map((item, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', marginBottom: 4 }}>
                    <Box sx={{ textAlign: 'left', marginRight: 4 }}>
                      <Typography sx={{ fontFamily: 'Raleway', fontSize: '25px', fontWeight: 600 }} gutterBottom>
                        {item.title}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Box
                          sx={{
                            backgroundColor: 'rgba(104, 176, 227, 1)',
                            width: 6,
                            height: 60,
                            opacity: 1,
                            borderRadius: 5,
                            marginRight: 2,
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '30px',
                            whiteSpace: 'pre-line',
                            textAlign: 'left',
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      component="img"
                      src={item.image}
                      alt={`${item.title} Image`}
                      sx={{
                        width: 295.06,
                        height: 403.53,
                        opacity: 1,
                        border: '1px solid transparent',
                        borderRadius: 2,
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4 }}>
                {data.filter((_, i) => i % 2 !== 0).map((item, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', marginBottom: 4 }}>
                    <Box
                      component="img"
                      src={item.image}
                      alt={`${item.title} Image`}
                      sx={{
                        width: 295.06,
                        height: 403.53,
                        opacity: 1,
                        border: '1px solid transparent',
                        borderRadius: 2,
                        marginRight: 4,
                      }}
                    />
                    <Box sx={{ textAlign: 'right', marginLeft: 4 }}>
                      <Typography sx={{ fontFamily: 'Raleway', fontSize: '25px', fontWeight: 600 }} gutterBottom>
                        {item.title}
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Typography
                          sx={{
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontWeight: 500,
                            lineHeight: '30px',
                            whiteSpace: 'pre-line',
                            textAlign: 'right',
                          }}
                        >
                          {item.description}
                        </Typography>
                        <Box
                          sx={{
                            backgroundColor: 'rgba(104, 176, 227, 1)',
                            width: 6,
                            height: 60,
                            opacity: 1,
                            borderRadius: 5,
                            marginLeft: 2,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default Features;
