import React, { useState } from "react";
import Header from "../components/Header";
import Book from "../components/BookCard";
import { useParams } from "react-router-dom";
import { getUserProfile, getUserBookShelf } from "../services/api";

export default function BookShelf() {
  const [modal, setModal] = React.useState(false);

  const filterOptions = ["All Books", "Favourites", "Reading", "Wishlist"];
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [selectedBooks, setSelectedBooks] = useState([]);

  let { userName } = useParams();
  const [userInfo,setUserInfo] = React.useState(null);
  React.useEffect(()=>{
    getUserProfile(userName).then(({_id,userInfo:{firstName,userName,userProfileUrl}}) => {
      // getOtherUserProfile(_id).then(({numberOfBookCircle,
      //   numberOfBookClub,
      //   numberOfFollowers,
      //   numberOfFollowing,
      //   numberOfFriends,bookShelf})=>{
      //     setUserInfo({_id,userName,name:firstName,bio:presentation,imageUrl:userProfileUrl,friendsCount: numberOfFriends,
      //       followingCount: numberOfFollowing,
      //       podcastsCount: numberOfBookCircle,
      //       clubsCount: numberOfBookClub,
      //       reviewCount: reviews.length,favoriteBooks:bookShelf.filter(({isLiked})=>isLiked).map(({bookInfo:{imageUrl}})=>({imageUrl})),reviews:reviews.map(({_id,message,rating,bookInfo:{title,imageUrl}})=>({_id,message,rating,name:title,imageUrl}))})
      //   // console.log('USERDATA:',bookShelf);
      // })
      getUserBookShelf(_id).then((books)=>{
        setUserInfo({_id,userName,name:firstName,userProfileUrl,books});
        setSelectedBooks(books);
      });
      setUserInfo({_id,userName,name:firstName,userProfileUrl});
      // setAmazonId(userData.userInfo.amazonAffiliateId)
    })
  },[userName])

  return (
    <>
      <div className="relative min-h-screen scroll-smooth">
        <Header setModal={setModal} modal={modal} />
        {userInfo&&<div className="flex justify-center pt-24 pb-[120px] sm:pb-[180px]">
          <div className="flex flex-col items-center gap-6 sm:gap-8 text-white font-body">
            <div className="flex flex-col items-center gap-1 sm:gap-1.5">
              <div
                className="w-48 h-48 sm:w-72 sm:h-72 rounded-full bg-cover bg-bottom bg-no-repeat overflow-hidden"
                style={{
                  backgroundImage: `url(${userInfo.userProfileUrl})`,
                }}
              ></div>
              <p className="font-title font-bold uppercase text-title-2 sm:text-title-1">
                {userInfo.name}
              </p>
            </div>
            {userInfo.books&&<div className="flex felx-row items-center p-4 sm:p-6 gap-5 sm:gap-12 text-gray-200">
              {filterOptions.map((filter) => {
                let bookCount='';
                switch(filter){
                  case 'All Books':
                    bookCount=userInfo.books.length;
                    break;
                  case 'Favourites':
                    bookCount=userInfo.books.filter(({isLiked})=>isLiked).length;
                    break;
                  case 'Reading':
                    bookCount=userInfo.books.filter(({status})=>status==="ACTIVE").length;
                    break;
                  case 'Wishlist':
                    bookCount=userInfo.books.filter(({status})=>status==="INACTIVE").length;
                }
                return (
                <div onClick={()=>{
                  switch(filter){
                    case 'All Books':
                      setSelectedBooks(userInfo.books);
                      setSelectedFilter('All Books');
                      break;
                    case 'Favourites':
                      setSelectedBooks(userInfo.books.filter(({isLiked})=>isLiked));
                      setSelectedFilter('Favourites');
                      break;
                    case 'Reading':
                      setSelectedBooks(userInfo.books.filter(({status})=>status==="ACTIVE"));
                      setSelectedFilter('Reading');
                      break;
                    case 'Wishlist':
                      setSelectedBooks(userInfo.books.filter(({status})=>status==="INACTIVE"));
                      setSelectedFilter('Wishlist');
                  }
                }}
                  className={
                    (selectedFilter === filter &&
                      "rounded-lg bg-gradient1 p-2 sm:p-3 ") +
                    ` flex flex-col sm:w-32 items-center text-body-2 sm:text-body-1 hover:cursor-pointer`
                  }
                >
                  <p className="font-bold">{bookCount}</p>
                  <p className="">{filter}</p>
                </div>
              )})}
            </div>}
            <div className="flex flex-col items-center">
              <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8 px-4 sm:px-8">
                {selectedBooks.map(({bookInfo,_id})=><a
                      key={bookInfo._id} href={`${location.protocol}//${location.host}/book/${bookInfo._id}`}
                    ><Book book={bookInfo} /></a>)}
              </div>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
}
