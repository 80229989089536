import React, { useEffect, useState } from "react";
import NavBar from "../components/home/header";
import { Box, Typography, Container, Grid, Card, CardContent, Fab, Modal, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Experience from "../components/home/Experience";
import Highlights from "../components/home/highlites";
import Features from "../components/home/features";
import Testimonials from "../components/home/testimonials";
import FAQs from "../components/home/FAQ";
import FullScreenVideo from "../components/home/video";
import Pricing from "../components/home/Pricing";
import Footer from "../components/Footer";
import Applelogo from '../assets/images/Frame 28.png';
import Googlelogo from '../assets/images/Frame 27.png';
import { animateScroll as scroll } from 'react-scroll';
import HomePhoto from '../assets/images/Screen images 3.png';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

const StyledContainer = styled(Container)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(8),
  color: '#FFFFFF',
  background: 'rgba(2, 19, 26, 1)',
}));

const HighlightText = styled(Box)(({ theme }) => ({
  color: '#FDBB30',
}));

const OverlappingCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  top: '70px',
  margin: '0 auto',
  borderRadius: '16px',
  padding: theme.spacing(2),
  backgroundColor: '#E0F7FA',
  textAlign: 'center',
  maxHeight: '200px',
  maxWidth: '500px',
  width: '100%',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down('sm')]: {
    top: '15px',
    width: '90%',
    padding: theme.spacing(1),
  },
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '500px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '16px',
}));

const BackToTopButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: 'gold',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FDBB30',
  color: '#fff',
  padding: theme.spacing(1.5, 4), // Adjust padding for better fit
  borderRadius: '50px', // Large value to make the button oval
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '18px', // Adjust font size as needed
  '&:hover': {
    backgroundColor: '#e6a521',
  },
}));

export default function HomapageNew() {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:1150px)');
  const [showBackToTop, setShowBackToTop] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  // Function to get the correct app link based on device type
  const getAppLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "https://apps.apple.com/us/app/moniro/id1668817182";
    } else if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=app.moniro.talk";
    }
    return "#"; // Default or fallback URL
  };

  return (
    <>
      <div style={{ background: 'rgba(2, 19, 26, 1)', paddingTop: '10px', paddingBottom: '40px' }}>
        <NavBar onGetAppClick={handleOpen} />
        <StyledContainer id="home">
          <Grid container alignItems="center" justifyContent="center" spacing={4} sx={{ paddingTop: isMobile ? 'auto' : '80px' }} direction={isMobile ? 'column-reverse' : 'row'}>
            <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '48px', fontFamily: 'Raleway' }}>
                Where <HighlightText component="span">Book Lovers</HighlightText> Meet
              </Typography>
              <Typography variant="h6" sx={{ marginTop: '20px', fontFamily: 'Poppins', fontSize: '24px' }}>
                Enjoy Book talks, join clubs, make friends, Elevate your reading!
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '10px' }}>
                {isMobile ? (
                  <StyledButton variant="contained" href={getAppLink()} target="_blank" rel="noopener noreferrer">
                    Get App
                  </StyledButton>
                ) : (
                  <>
                    <a href="https://apps.apple.com/us/app/moniro/id1668817182" target="_blank" rel="noopener noreferrer">
                      <img src={Applelogo} alt="App Store" style={{ height: '50px', cursor: 'pointer' }} />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=app.moniro.talk" target="_blank" rel="noopener noreferrer">
                      <img src={Googlelogo} alt="Google Play" style={{ height: '50px', cursor: 'pointer' }} />
                    </a>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ width: '100%', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={HomePhoto} alt="Mobile App" style={{ maxWidth: '560px', width: '100%', height: 'auto' }} />
              </Box>
            </Grid>
          </Grid>
        </StyledContainer>
      </div>

      <Box flex="1">
        <center style={{
          paddingTop: 50,
          width: '100%',
          height: '100%',
          background: 'rgba(5, 36, 51, 1)'
        }}> <FullScreenVideo /></center>
        <section style={{ marginTop: '-140px' }} id="experience">
          <Experience />
        </section>
        <section id="highlight">
          <Highlights />
        </section>
        <section style={{backgroundColor:'rgba(5, 36, 51, 1)'}} id="features">
          <Features />
        </section>
        <section id="testimonies">
          <Testimonials />
        </section>
        <section id="pricing">
          <Pricing />
        </section>
        <section id="faq">
          <FAQs />
        </section>
      </Box>
      <Footer />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <ModalContent>
          <OverlappingCard>
            <CardContent>
              <Typography variant="h5" component="div">
                Join a global community of passionate readers
              </Typography>
              <Typography variant="h6" color="textSecondary" component="div">
                Download Moniro
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '10px', gap: '10px' }}>
                <a href="https://apps.apple.com/us/app/moniro/id1668817182" target="_blank" rel="noopener noreferrer">
                  <img src={Applelogo} alt="App Store" style={{ height: '40px', cursor: 'pointer' }} />
                </a>
                <a href="https://play.google.com/store/apps/details?id=app.moniro.talk" target="_blank" rel="noopener noreferrer">
                  <img src={Googlelogo} alt="Google Play" style={{ height: '40px', cursor: 'pointer' }} />
                </a>
              </Box>
            </CardContent>
          </OverlappingCard>
        </ModalContent>
      </Modal>
      {showBackToTop && (
        <BackToTopButton color="primary" aria-label="back to top" onClick={scrollToTop}>
          <KeyboardDoubleArrowUpIcon />
        </BackToTopButton>
      )}
    </>
  );
}
