import logoSrc from "../assets/images/logo.svg";

const Links = {
  PrivacyPolicy: "/privacyandpolicy",
  Terms: "/privacyandpolicy",
  Facebook: "#",
  Twitter: "#",
  Instagram: "https://www.instagram.com/moniro.app",
};
function Footer() {
  return (
    <footer style={{width: '100%', paddingTop: '90px' }}  className="flex flex-col w-screen bg-gradient1 bottom-0 text-white py-4 px-8 sm:px-16 items-center gap-4 sm:gap-6 lg:gap-8 border-t border-white">
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-col gap-1 sm:gap-2 lg:gap-4 items-center">
          <div className="flex flex-row align-middle gap-1 sm:gap-3">
            {/* <p className="font-body text-body-1 sm:text-title-2">Moniro</p> */}
          </div>
          <div className="text-center font-body text-small-1 sm:text-body-2">
            <p className="text-body-2 sm:text-body-1 font-light -m-2">
              Stockholm, Sweden
            </p>
            <br />
            <a
              href={Links.PrivacyPolicy}
              className="text-white underline hover:text-gray-300"
            >
              Privacy Policy
            </a>{" "}
            and
            <br />
            <a
              href={Links.Terms}
              className="text-white underline hover:text-gray-300"
            >
              Terms and Conditions
            </a>
          </div>
        </div>
        <div className="flex flex-col items-center gap-1 sm:gap-2 lg:gap-4">
          <div className="flex gap-4 mb-2">
            <a
              href={Links.Facebook}
              className="icon-[simple-icons--facebook] text-white hover:text-gray-300 w-5 h-5 sm:w-7 lg:w-8 sm:h-7 lg:h-8"
            ></a>
            <a
              href={Links.Twitter}
              className="icon-[simple-icons--x] text-white hover:text-gray-300 w-5 h-5 sm:w-7 lg:w-8 sm:h-7 lg:h-8"
            ></a>
            <a
              href={Links.Instagram}
              className="icon-[simple-icons--instagram] text-white hover:text-gray-300 w-5 h-5 sm:w-7 lg:w-8 sm:h-7 lg:h-8"
            ></a>
          </div>
          <div className="text-center font-body text-small-1 leading-5 sm:leading-6 lg:leading-7 sm:text-body-2">
            <a href="mailto:contact@moniro.app" className="hover:text-gray-300">
              contact@moniro.app
            </a>
          </div>
        </div>
      </div>
      <div className="text-center font-body text-small-1 sm:text-body-2">
        Copyright © MONIRO All rights reserved
      </div>
    </footer>
  );
}

export default Footer;
