export default function dateTimeFormat(timeString) {
    return new Date(timeString).toLocaleDateString(
        undefined,
        {
          year: "numeric",
          weekday: "long",
          hour: "numeric",
          minute: "numeric",
          month: "short",
          day: "numeric",
        }
      )
}