function ReviewCard({ review, forUser }: any) {
  const fullStars = Math.floor(review.rating);
  const halfAStar = review.rating - fullStars;

  const starsArray = Array(fullStars).fill(1);

  return (
    <div className="w-fit h-fit flex flex-col text-small-1 sm:text-body-2 items-center gap-4 sm:gap-6 p-2 sm:p-4 font-body bg-gradient1/60 backdrop-blur-sm rounded-lg text-white sm:rounded-xl">
      <p className="max-w-56 sm:max-w-96">{review.message}</p>
      <div className="flex flex-row justify-between pr-6 w-full">
        <div className="flex flex-row items-center w-fit gap-4">
          <div
            className={
              (forUser
                ? `rounded-lg drop-shadow-book h-14 sm:h-20 `
                : `rounded-full h-10 sm:h-14 `) +
              ` w-10 sm:w-14 bg-cover bg-center`
            }
            style={{ backgroundImage: `url(${review.imageUrl})` }}
          ></div>
          <div className="flex flex-col gap-1">
            {forUser && (
              <p className="text-small-1 sm:text-body-2 text-gray-400 -mb-1">
                On
              </p>
            )}
            <p className="text-body-2 sm:text-body-1">{review.name}</p>
            <div className="w-28 sm:w-36 items-start flex gap-0.5">
              {starsArray.map((v, i) => (
                <span
                  key={i}
                  className="icon-[fluent--star-24-filled] bg-yellow-400 drop-shadow-card w-3 h-3 sm:w-4 sm:h-4"
                ></span>
              ))}
              {halfAStar > 0 && (
                <span className="icon-[fluent--star-half-24-filled] bg-yellow-400 drop-shadow-card w-3 h-3 sm:w-4 sm:h-4"></span>
              )}
            </div>
          </div>
        </div>
        <span className="icon-[material-symbols--format-quote]  drop-shadow-card w-6 h-6 sm:w-8 sm:h-8"></span>
      </div>
    </div>
  );
}
export default ReviewCard;
