import React, { useEffect, useState } from 'react';
import { Box, Typography, Avatar, Card, CardContent, Button, useMediaQuery, useTheme } from '@mui/material';
import { styled, keyframes } from '@mui/system';
import { getBookRecordingweb } from '../../services/api';
import dayjs from 'dayjs';

const blink = keyframes`
  0%, 100% {
    border-color: gold;
  }
  50% {
    border-color: transparent;
  }
`;

const MarqueeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflowX: 'scroll',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
  '& > div': {
    display: 'inline-block',
  },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const CardWrapper = styled(Card)(({ theme, backgroundColor, isMobile }) => ({
  width: isMobile ? '84%' : '400px',
  maxWidth: '400px',
  height: '152px',
  display: 'flex',
  flexDirection: isMobile ? 'row' : 'column',
  justifyContent: 'space-between',
  position: 'relative',
  margin: theme.spacing(3),
  overflow: 'visible',
  borderRadius: '20px',
  backgroundColor: backgroundColor,
  alignItems: 'start',
  [theme.breakpoints.down('md')]: {
    marginTop: 10,
    padding: 20,
  },
}));

const ImageWrapper = styled(Box)({
  position: 'absolute',
  left: '10px',
  top: '-33px',
  width: '83px',
  height: '120px',
  zIndex: 1,
  borderRadius: '4px 0px 0px 0px',
  border: '3px solid transparent',
});

const AvatarGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: '10px',
  left: '10px',
  '& > *': {
    marginLeft: '-5px',
  },
}));

const Tooltip = styled(Box)(({ theme, position }) => ({
  position: 'absolute',
  top: position.top,
  left: position.left,
  background: 'rgba(0, 0, 0, 0.75)',
  color: 'white',
  padding: '8px 12px',
  borderRadius: '4px',
  zIndex: 1000,
  transform: 'translateY(-100%)',
  whiteSpace: 'nowrap',
  pointerEvents: 'none',
}));

const truncateTitle = (title) => title.length > 25 ? `${title.substring(0, 25)}...` : title;

const rgbaToCssColor = (rgba) => {
  const [r, g, b, a] = rgba.split(',').map(Number);
  return `rgba(${r},${g},${b},${a / 255})`;
};

const formatDate = (dateString) => dayjs(dateString).format('DD MMM YYYY');


const VideoPopUp = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',  
  height: '45%',  
  maxWidth: '800px',   
  maxHeight: '450px',  
  background: 'black',
  padding: theme.spacing(2),
  zIndex: 2000,
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  '@media (max-width: 600px)': { 
    width: '95%',
    height: 'auto',
  }
}));

const Highlights = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:1150px)');
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [bookRecordings, setBookRecordings] = useState([]);
  const [tooltip, setTooltip] = useState({ visible: false, name: '', position: { top: 0, left: 0 } });
  const [videoUrl, setVideoUrl] = useState('');
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recordingsData = await getBookRecordingweb();
        const filteredData = recordingsData.slice(1); 
        setBookRecordings(filteredData);
        console.log('Filtered Data', filteredData); 
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const handleAvatarClick = (name, event) => {
    const rect = event.target.getBoundingClientRect();
    setTooltip({
      visible: true,
      name,
      position: {
        top: `${rect.top + window.scrollY}px`,
        left: `${rect.left + window.scrollX + rect.width + 10}px`,
      },
    });

    setTimeout(() => {
      setTooltip({ ...tooltip, visible: false });
    }, 2000);
  };

  const handleWatchButtonClick = (url) => {
    setVideoUrl(url);
    setShowVideo(true);
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
    setVideoUrl('');
  };

  return (
    <Box
      alignItems={'center'}
      alignContent="center"
      textAlign="center"
      style={{
        background: 'rgba(2, 19, 26, 1)',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
      }}
      p={2}
    >
      {tooltip.visible && <Tooltip position={tooltip.position}>{tooltip.name}</Tooltip>}
      {showVideo && (
  <VideoPopUp onClick={(e) => e.stopPropagation()}>
    <button onClick={handleCloseVideo} 
      style={{ position: 'absolute', top: '10px', right: '10px', background: 'transparent', border: 'none', color: 'white', fontSize: '20px', cursor: 'pointer' }}>✕</button>
    <video controls autoPlay 
      style={{ 
        width: '100%', 
        height: '100%', 
        objectFit: 'contain', 
        borderRadius: '8px' 
      }}>
      <source src={videoUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </VideoPopUp>
)}
      <Box display="inline-block" px={3} py={1}>
        <Typography variant="h4" style={{ fontFamily: 'Raleway', color: 'white', fontWeight: 600, fontSize: '48px' }}>
          Book Talk
        </Typography>
      </Box>
      <Typography variant="subtitle1" mt={2} style={{ color: 'rgba(255, 255, 255, 1)', fontFamily: 'Poppins', fontWeight: 500, fontSize: '30px' }}>
        Highlighted feature
      </Typography>
      <Box
        display="flex"
        flexDirection={isMobile || isTablet ? 'column' : 'row'}
        overflow="hidden"
        className="hidden-scrollbar"
        style={{
          paddingTop: '50px',
          whiteSpace: 'nowrap',
          overflowX: isMobile || isTablet ? 'none' : 'scroll',
          paddingBottom: '20px',
          marginBottom: '-20px',
          marginRight: '-20px',
        }}
      >
        {bookRecordings?.length > 0 ? (
          !isMobile && !isTablet ? (
            <MarqueeContainer>
              {bookRecordings.map((podcast) => {
                const allParticipants = [
                  podcast.bookCircle.user,
                  ...(podcast.bookCircle.coAdmins || []),
                  ...(podcast.bookCircle.participants || []),
                  ...(podcast.bookCircle.speakers || []),
                ].filter(participant => participant && participant.userInfo);
                return (
                  <CardWrapper
                    key={podcast.bookCircle._id}
                    backgroundColor={rgbaToCssColor(podcast.bookCircle.book.bookCoverColor)}
                    isMobile={isMobile}
                  >
                    <ImageWrapper>
                      <img
                        src={podcast.bookCircle.book.imageUrl}
                        alt={podcast.bookCircle.book.title}
                        style={{ width: '236px', height: '100%', border: '2px solid rgba(2, 19, 26, 1)', borderRadius: '5px' }}
                      />
                    </ImageWrapper>
                    <CardContent
                      style={{
                        width: '260px',
                        marginLeft: isMobile ? '50px' : '80px',
                        marginTop: isMobile ? '-3px' : '10px',
                        paddingTop: '3px',
                        textAlign: 'left',
                        color: 'white',
                        fontFamily: 'Poppins',
                      }}
                    >
                      <Typography variant="subtitle2">{truncateTitle(podcast.bookCircle.book.author)}</Typography>
                      <Typography variant="h6">{truncateTitle(podcast.bookCircle.book.title)}</Typography>
                      <Typography variant="body2">{formatDate(podcast.bookCircle.bookSessions[0]?.startDate)}</Typography>
                    </CardContent>
                    <AvatarGroup>
                      {allParticipants.slice(0, 5).map((participant, index) => (
                        <Avatar
                          key={index}
                          style={{
                            height: 40,
                            width: 40,
                            border: participant.userInfo._id === podcast.bookCircle.user._id
                              ? '4px solid transparent'
                              : `2px solid ${rgbaToCssColor(podcast.bookCircle.book.bookCoverColor)}`,
                            borderRadius: '50%',
                            background: participant.userInfo._id === podcast.bookCircle.user._id
                              ? 'linear-gradient(45deg, #ff8e53, #ff8e53)' // Gradient border color
                              : 'white',
                            boxShadow: participant.userInfo._id === podcast.bookCircle.user._id ? '0 0 0 4px rgba(255, 105, 135, 0.2)' : 'none',
                            marginLeft: index === 0 ? '0px' : '-5px',
                          }}
                          alt={`${participant.userInfo.firstName} ${participant.userInfo.lastName}`}
                          src={participant.userInfo.userProfileUrl ? participant.userInfo.userProfileUrl : `https://ui-avatars.com/api/?name=${encodeURIComponent(participant.userInfo.firstName[0])}${encodeURIComponent(participant.userInfo.lastName[0])}&background=random`}
                          onClick={(e) => handleAvatarClick(`${participant.userInfo.firstName} ${participant.userInfo.lastName}`, e)}
                        />
                      ))}
                      {allParticipants.length > 5 && (
                        <Typography
                          variant="body2"
                          style={{
                            fontSize:'14px',
                            color: 'white',
                            marginLeft: '3px',
                            fontWeight: 'bold',
                            alignSelf: 'center',
                          }}
                        >
                          +{allParticipants.length - 5} 
                        </Typography>
                      )}
                    </AvatarGroup>
                    <Button
                      variant="contained"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px',
                        background: 'rgba(5, 36, 51, 1)',
                        borderRadius: '5px',
                        fontFamily: 'Poppins',
                        padding: '5px 10px',
                        color: 'white',
                        cursor: 'pointer',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      }}
                      onClick={() => handleWatchButtonClick(podcast.recordings[0].recordingUrl)}
                      >
                      Watch
                    </Button>
                  </CardWrapper>
                );
              })}
            </MarqueeContainer>
          ) : (
            bookRecordings.map((podcast) => {
              const allParticipants = [
                podcast.bookCircle.user,
                ...(podcast.bookCircle.coAdmins || []),
                ...(podcast.bookCircle.participants || []),
                ...(podcast.bookCircle.speakers || []),
              ].filter(participant => participant && participant.userInfo);
              return (
                <center key={podcast._id}>
                  <CardWrapper
                    key={podcast.bookCircle._id}
                    backgroundColor={rgbaToCssColor(podcast.bookCircle.book.bookCoverColor)}
                    isMobile={isMobile}
                  >
                    <ImageWrapper>
                      <img
                        src={podcast.bookCircle.book.imageUrl}
                        alt={podcast.bookCircle.book.title}
                        style={{ width: '236px', height: '100%', border: '2px solid rgba(2, 19, 26, 1)', borderRadius: '5px' }}
                      />
                    </ImageWrapper>
                    <CardContent
                      style={{
                        marginLeft: isMobile ? '60px' : '80px',
                        marginTop: isMobile ? '-10px' : '10px',
                        paddingTop: '3px',
                        textAlign: 'left',
                        color: 'white',
                        fontFamily: 'Poppins',
                      }}
                    >
                      <Typography variant="subtitle2">{truncateTitle(podcast.bookCircle.book.author)}</Typography>
                      <Typography variant="h6">{truncateTitle(podcast.bookCircle.book.title)}</Typography>
                      <Typography variant="body2">{formatDate(podcast.bookCircle.bookSessions[0]?.startDate)}</Typography>
                    </CardContent>
                    <AvatarGroup>
                      {allParticipants.slice(0, 5).map((participant, index) => (
                        <Avatar
                          key={index}
                          style={{
                            height: 40,
                            width: 40,
                            cursor: 'pointer',
                            border: `2px solid ${rgbaToCssColor(podcast.bookCircle.book.bookCoverColor)}`,
                            marginLeft: index === 0 ? '0px' : '-5px',
                            animation: participant.userInfo._id === podcast.bookCircle.user._id ? `${blink} 1s infinite` : 'none',
                          }}
                          alt={`${participant.userInfo.firstName} ${participant.userInfo.lastName}`}
                          src={participant.userInfo.userProfileUrl ? participant.userInfo.userProfileUrl : `https://ui-avatars.com/api/?name=${encodeURIComponent(participant.userInfo.firstName[0])}${encodeURIComponent(participant.userInfo.lastName[0])}&background=random`}
                          onClick={(e) => handleAvatarClick(`${participant.userInfo.firstName} ${participant.userInfo.lastName}`, e)}
                        />
                      ))}
                      {allParticipants.length > 5 && (
                        <Typography
                          variant="body2"
                          style={{
                            fontSize:'14px',
                            color: 'white',
                            marginLeft: '2px',
                            fontWeight: 'bold',
                            alignSelf: 'center',
                          }}
                        >
                          +{allParticipants.length - 5} 
                        </Typography>
                      )}
                    </AvatarGroup>
                    <Button
                      variant="contained"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px',
                        background: 'rgba(5, 36, 51, 1)',
                        borderRadius: '5px',
                        fontFamily: 'Poppins',
                        padding: '5px 10px',
                        color: 'white',
                        cursor: 'pointer',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      }}
                      onClick={() => handleWatchButtonClick(podcast.recordings[0].recordingUrl)}
                    >
                      Watch
                    </Button>
                  </CardWrapper>
                </center>
              );
            })
          )
        ) : (
          <Typography variant="body1" style={{ color: 'white' }}>No recordings found.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Highlights;
