import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Rating from '@mui/material/Rating';
import baseUrl from '../../services/api';
import img1 from '../../assets/images/hanadi.png.webp'
import img2 from '../../assets/images/ahmed-siraj.webp'
import img3 from '../../assets/images/clas-lundhagen.webp'
import img4 from '../../assets/images/marko.webp'
import img5 from '../../assets/images/Ellipse 11.png'
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const PREFIX = 'Testimonials';
const classes = {
  root: `${PREFIX}-root`,
  stats: `${PREFIX}-stats`,
  statBox: `${PREFIX}-statBox`,
  
  card: `${PREFIX}-card`,
  quoteIcon: `${PREFIX}-quoteIcon`,
  testimonialContent: `${PREFIX}-testimonialContent`,
  testimonialHeader: `${PREFIX}-testimonialHeader`,
  avatar: `${PREFIX}-avatar`,
  avatarActive: `${PREFIX}-avatarActive`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: '#0D1B2A', // Match the background color in your design
    color: '#fff', // White text color
    padding: theme.spacing(4),
    // minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    
  },
  [`& .${classes.stats}`]: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  [`& .${classes.statBox}`]: {
    textAlign: 'center',
    margin: theme.spacing(2),
  },
  [`& .${classes.card}`]: {
    maxWidth: '720px',
    width: '100%',
    backgroundColor: '#0B425B',
    borderRadius: '16px',
    padding: theme.spacing(4),
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4), // Set padding for mobile devices
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(4), // Set padding for tablet devices
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(4), // Set padding for larger screens
      marginLeft: '200px', // Added padding to the left to center the card
    },
  },
  [`& .${classes.quoteIcon}`]: {
    position: 'absolute',
    bottom: '-20px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#0B425B',
    borderRadius: '50%',
    padding: theme.spacing(1),
  },
  [`& .${classes.testimonialContent}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.testimonialHeader}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  [`& .${classes.avatar}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(0, 1),
    transition: 'width 0.3s, height 0.3s',
  },
  [`& .${classes.avatarActive}`]: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: theme.spacing(0, 1),
    transition: 'width 0.3s, height 0.3s',
  },
}));

const testimonials = [
  {
    name: 'Hanadi',
    profession: '',
    rating: 5,
    text: 'Väldigt fin design och intuitiv app. Kommer definitivt att använda och rekommendera till vänner!', 
    avatar: img1,
  },
  {
    name: 'Ahmed',
    profession: '',
    rating: 5.0,
    text: 'Som lärare i över 10 år så tror jag att appen kommer öka intresset och läslusten. Otroligt fint!',
    avatar: img2,
  },
  {
    name: 'Clas',
    profession: '',
    rating: 4.5,
    text: 'Jag älskar funktionen att kunna få tips på personer som läst och gillar samma böcker som jag.',
    avatar: img3,
  },
  {
    name: 'Marko',
    profession: '',
    rating: 5.0,
    text: 'Jag skulle personligen absolut använda denna app för att hitta eller tipsa om riktigt bra kvalitetsböcker.',
    avatar: img4,
  }
  
];

const Testimonials: React.FC = () => {
  const [index, setIndex] = React.useState(0);
  const [data, setData] = React.useState({ podcastsHosted: 0, bookClubs: 0, usersWorldwide: 0 });

  useEffect(() => {
    axios.get(`${baseUrl}/analytics/getAggregatedValuesAnalytics`)
      .then(response => {
        console.log("Dataaaaa", response.data);
        const { podcastsHosted, bookClubs, usersWorldwide } = response.data;
        setData({ podcastsHosted, bookClubs, usersWorldwide });
      })
      .catch(error => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleStepChange = (step: number) => {
    setIndex(step);
  };

  return (
    <Root className={classes.root}>
      <Container style={{alignContent: 'center', alignItems: 'center'}}>
        

        <Box className={classes.card}>
          <Typography style={{
            paddingBottom:'10px' ,
          }} variant="h6">And here is what they say</Typography>
          <Box className={classes.testimonialHeader}>
            {testimonials.map((testimonial, i) => (
              <Avatar
                key={i}
                src={testimonial.avatar}
                alt={testimonial.name}
                className={
                  index === i ? classes.avatarActive : classes.avatar
                }
                
              />
            ))}
          </Box>
          <AutoPlaySwipeableViews
            index={index}
            onChangeIndex={handleStepChange}
          >
            {testimonials.map((testimonial, i) => (
              <Box key={i} className={classes.testimonialContent}>
                <Typography variant="h5">{testimonial.name}</Typography>
                <Typography>{testimonial.profession}</Typography>
                <Rating
                  value={testimonial.rating}
                  precision={0.5}
                  readOnly
                  sx={{ color: '#FFD700' }} // Gold color for rating stars
                />
                <Typography>{testimonial.text}</Typography>
              </Box>
            ))}
          </AutoPlaySwipeableViews>
          <FormatQuoteIcon className={classes.quoteIcon} fontSize="large" />
        </Box>
      </Container>
    </Root>
  );
};

export default Testimonials;
