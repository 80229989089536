import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography } from '@mui/material';
import { Link as ScrollLink, Events, scrollSpy } from 'react-scroll';
import Logo from '../../assets/images/logo.svg';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DownloadIcon from '@mui/icons-material/Download';


const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#0B425B',
  height: '65px',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    borderRadius: '50px',
    margin: '40px 5.125%',
    padding: '0px 0px',
    width: '90%',
  },
  [theme.breakpoints.down('md')]: {
    backgroundColor: 'transparent',
    margin: '0',
    padding: '5px 5px',
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  minWidth: 'auto',
  color: '#FFFFFF',
  fontWeight: 'bold',
  textTransform: 'capitalize',
  padding: theme.spacing(1),
  '&.Mui-selected': {
    color: '#FDBB30',
    fontWeight: 'bold',
  },
  '&:hover': {
    backgroundColor: 'rgba(253, 187, 48, 0.2)',
  },
  [theme.breakpoints.down('md')]: {
    color: '#0B425B',
    fontWeight: 600,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '25px',
  backgroundColor: '#FDBB30',
  color: '#0B425B',
  fontWeight: '500',
  '&:hover': {
    backgroundColor: '#C2E0FA',
  },
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '.MuiDrawer-paper': {
    width: '204px',
    height: '411px',
    backgroundColor: '#E7F3FE',
    padding: '24px 48px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '8px',
    overflowY: 'auto',
  },
}));

const GreyDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: '#B0B0B0',
  height: '1px',
  width: '100%',
  margin: theme.spacing(2, 0),
}));

const TabDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: '#FDBB30',
  height: '2px',
  marginTop: theme.spacing(1),
}));

const NavBar: React.FC<{ onGetAppClick: () => void }> = ({ onGetAppClick }) => {
  const [value, setValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:1150px)');

  useEffect(() => {
    Events.scrollEvent.register('begin', function () { });
    Events.scrollEvent.register('end', function () { });
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const handleSetActive = (to: string) => {
    const index = ['home', 'experience', 'highlight', 'features', 'testimonies', 'pricing', 'faq', 'shop'].indexOf(to);
    setValue(index);
  };

  const handleDrawerItemClick = (index: number) => {
    setValue(index);
    setDrawerOpen(false);
  };

  const drawer = (
    <>
      <IconButton onClick={() => setDrawerOpen(false)} sx={{ alignSelf: 'flex-end' }}>
        <CloseIcon />
      </IconButton>
      <GreyDivider />
      <List>
        {['Home', 'Experience', 'Highlight', 'Features', 'Testimonies', 'Pricing', 'FAQ', 'Shop'].map((text, index) => (
          <React.Fragment key={text}>
            {text === 'Shop' ? (
              <ListItem button
                onClick={() => window.open('https://shop.moniro.app/', '_blank')}>
                <ListItemText primary={text} />
              </ListItem>
            ) : (
              <ScrollLink
                to={text.toLowerCase()}
                spy={true}
                smooth={true}
                duration={500}
                onSetActive={handleSetActive}
                offset={-64}
              >
                <ListItem button onClick={() => handleDrawerItemClick(index)}>
                  <ListItemText primary={text} />
                </ListItem>
              </ScrollLink>
            )}
            {index < 7 && <GreyDivider />}
          </React.Fragment>
        ))}
      </List>
    </>
  );

  return (
    <StyledAppBar
      position={isMobile ? 'static' : 'fixed'}
      sx={{
        top: isMobile ? 'auto' : 0,
        zIndex: isMobile ? 'auto' : theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', padding: '0 16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={Logo} alt="Moniro" style={{ height: '40px', marginRight: '5px' }} />
          <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '30px', fontFamily: 'Raleway', color: '#FFFFFF' }}>
            Moniro
          </Typography>
        </Box>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
              sx={{ marginLeft: 'auto' }}
            >
              <MenuIcon />
            </IconButton>
            <StyledDrawer
              anchor="right"
              open={drawerOpen}
              onClose={() => setDrawerOpen(false)}
            >
              {drawer}
            </StyledDrawer>
          </>
        ) : (
          <>
            <Tabs
              value={value}
              onChange={(e, newValue) => setValue(newValue)}
              textColor="inherit"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#FDBB30',
                },
              }}
            >
              {['Home', 'Experience', 'Highlight', 'Features', 'Testimonies', 'Pricing', 'FAQ'].map((text, index) =>
                text === 'Shop' ? (
                  <Tab
                    key={text}
                    label={text}
                    onClick={() => window.open('https://shop.moniro.app/', '_blank')}
                    sx={{
                      textTransform: 'capitalize',
                      color: '#FFFFFF',
                      '&:hover': { color: '#FDBB30' },
                    }}
                  />
                ) : (
                  <ScrollLink
                    key={text}
                    to={text.toLowerCase()}
                    spy={true}
                    smooth={true}
                    duration={500}
                    onSetActive={handleSetActive}
                    offset={-64}
                  >
                    <StyledTab label={text} />
                  </ScrollLink>
                )
              )}
            </Tabs>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 0.6,
      padding: '4px 8px',
      border: '1px solid #FDBB30', 
      borderRadius: '30px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#C2E0FA',
       
  
        '& svg, & span': {
          color: '#0B425B', // White color for both icon and text on hover
        
        },
      },
    }}
    onClick={() => window.open('https://shop.moniro.app/', '_blank')}
  >
    <StorefrontIcon
      sx={{
        color: '#FDBB30', 
        fontSize: '1.4rem',
      }}
    />
    <Typography
      sx={{
        color: '#FDBB30', 
        fontWeight: 'bold',
        fontFamily: 'Raleway, Arial, sans-serif', 
        fontSize: '1rem',
        ':hover':{
          color: '#0B425B'
        }
       
      }}
    >
      SHOP
    </Typography>
  </Box>
  <StyledButton
    variant="contained"
    onClick={onGetAppClick}
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 0.6,
      fontWeight: '600', 
      fontSize: '0.9rem',
      fontFamily: 'Raleway, Arial, sans-serif', 
      textTransform: 'capitalize',
      padding: '4px 8px',
      '& svg': {
        fontSize: '1.4rem', 
      },
    }}
  >
    <DownloadIcon /> 
    GET APP
  </StyledButton>
</Box>

          </>
        )}
      </Toolbar>
    </StyledAppBar>
  );
};

export default NavBar;
