export function Downloader({ modal, setModal }) {
    const Links = {
        Download: "#footer",
        Home: '/',
        PlayStore: "https://play.google.com/store/apps/details?id=app.moniro.talk",
        AppStore: "https://apps.apple.com/us/app/moniro/id1668817182"
        // Books: "#",
    };
    return <div
        className={"absolute z-10 " + (modal ? "" : "hidden")}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        onClick={() => {
            setModal(!modal);
        }}
    >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className="relative flex transform overflow-hidden rounded-lg justify-center bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
                >
                    <div className="flex flex-row gap-4">
                        <a
                            href={Links.PlayStore}
                            className="w-32 h-10 sm:w-64 sm:h-20 bg-contain rounded-sm bg-center bg-no-repeat bg-[url('./assets/images/Play.png')]"
                        ></a>
                        <a
                            href={Links.AppStore}
                            className="w-32 h-10 sm:w-64 sm:h-20 bg-contain rounded-sm bg-center bg-no-repeat bg-[url('./assets/images/AppStore.png')]"
                        ></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}