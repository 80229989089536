import React from 'react';
import { Box, styled } from '@mui/material';
import videofile from '../../assets/video/Moniro - Where Book-Lovers Meet.mp4';

const VideoContainer = styled(Box)(({ theme }) => ({
  width: '40%',
  height: '18%',
  borderRadius: '24px',
  border: '5px solid rgba(194, 224, 250, 1)',
  padding: '4px 3px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '44px',
  position: 'static',
  top: '120%',
  left: '80%',
  transform: 'translate(0%, -50%)',
  backgroundColor: 'rgba(5, 36, 51, 1)', 
  [theme.breakpoints.down('md')]: {
    width: '85%',
    height: 'auto',
    top: '90%',
  },
}));

const VideoPlayer = styled('video')(({ theme }) => ({
  width: '100%',
  height: '100%',
  borderRadius: '16px',
  objectFit: 'contain',  
  cursor: 'pointer',
}));

const VideoCard = () => {
  const handleVideoClick = (e) => {
    const videoElement = e.target;

   
    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.mozRequestFullScreen) { 
      videoElement.mozRequestFullScreen();
    } else if (videoElement.webkitRequestFullscreen) { 
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) { 
      videoElement.msRequestFullscreen();
    }
  };

  return (
    <VideoContainer>
      <VideoPlayer
        src={videofile}
        autoPlay
        muted
        loop
        controls
        onClick={handleVideoClick}
      />
    </VideoContainer>
  );
};

export default VideoCard;
