import React from "react";
import Header from "../components/Header";
import Book from "../components/BookCard";
import ReviewCard from "../components/ReviewCard";
import { getOtherUserProfile, getUserProfile } from "../services/api";
import { useParams } from "react-router-dom";

export default function UserPage() {
  let { userName } = useParams();
  const [modal, setModal] = React.useState(false);
  const [userInfo,setUserInfo] = React.useState(null);
  React.useEffect(()=>{
    getUserProfile(userName).then(({_id,userInfo:{firstName,userName,userProfileUrl,presentation}}) => {
      getOtherUserProfile(_id).then(({numberOfBookCircle,
        numberOfBookClub,
        numberOfFollowers,
        numberOfFollowing,
        numberOfFriends,bookShelf,reviews})=>{
          setUserInfo({_id,userName,name:firstName,bio:presentation,imageUrl:userProfileUrl,friendsCount: numberOfFriends,
            followingCount: numberOfFollowing,
            podcastsCount: numberOfBookCircle,
            clubsCount: numberOfBookClub,
            reviewCount: reviews.length,favoriteBooks:bookShelf.filter(({isLiked})=>isLiked).map(({bookInfo:{imageUrl,_id}})=>({imageUrl,_id})),reviews:reviews.map(({_id,message,rating,bookInfo:{title,imageUrl}})=>({_id,message,rating,name:title,imageUrl}))})
        // console.log('USERDATA:',bookShelf);
      })
      setUserInfo({_id,userName,name:firstName,bio:presentation,imageUrl:userProfileUrl,friendsCount: "...",
      followingCount: "...",
      podcastsCount: "...",
      clubsCount: "...",
      reviewCount: "...",});
      // setAmazonId(userData.userInfo.amazonAffiliateId)
    })
  },[userName])

  return (
    <>
      <div className="relative min-h-screen scroll-smooth">
        <Header setModal={setModal} modal={modal} />
        {userInfo&&<div className="flex justify-center pt-24 pb-[120px] sm:pb-[180px]">
          <div className="flex flex-col items-center gap-6 sm:gap-8 text-white font-body">
            <div className="flex flex-col items-center gap-1 sm:gap-1.5">
              <div
                className="w-48 h-48 sm:w-72 sm:h-72 rounded-full bg-cover bg-bottom bg-no-repeat overflow-hidden"
                style={{
                  backgroundImage: `url(${userInfo.imageUrl})`,
                }}
              ></div>
              <p className="font-title font-bold uppercase text-title-2 sm:text-title-1">
                {userInfo.name}
              </p>
              <p className="text-body-2 sm:text-body-1 text-gray-300">
                {userInfo.userName}
              </p>
              <p className="text-body-2 sm:text-body-1">{userInfo.bio}</p>
            </div>
            <div className="flex felx-row p-4 sm:p-6 gap-5 sm:gap-12 text-gray-200">
              <div className="flex flex-col w-16 sm:w-20 items-center text-body-2 sm:text-body-1">
                <p className="font-bold">{userInfo.friendsCount}</p>
                <p className="">Friends</p>
              </div>
              <div className="flex flex-col w-16 sm:w-20 items-center text-body-2 sm:text-body-1">
                <p className="font-bold">{userInfo.followingCount}</p>
                <p className="">Following</p>
              </div>
              <div className="flex flex-col w-16 sm:w-20 items-center text-body-2 sm:text-body-1">
                <p className="font-bold">{userInfo.podcastsCount}</p>
                <p className="">Podcasts</p>
              </div>
              <div className="flex flex-col w-16 sm:w-20 items-center text-body-2 sm:text-body-1">
                <p className="font-bold">{userInfo.clubsCount}</p>
                <p className="">Clubs</p>
              </div>
            </div>
            {userInfo.favoriteBooks&&<div className="flex flex-col items-center">
              <p className="text-body-2 sm:text-body-1 pb-4 sm:pb-6">
                Favourite Books
              </p>
              <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8 px-4 sm:px-8">
                {userInfo.favoriteBooks.map((book)=><a
                      key={book._id} href={`${location.protocol}//${location.host}/book/${book._id}`}
                    ><Book book={book} /></a>)}
              </div>
              <a
               href={`/${userName}/bookshelf`}
                className="underline mt-4 text-small-1 sm:text-body-2 font-light"
              >
                Go to Bookshelf
              </a>
            </div>}
            {userInfo.reviews&&<div className="flex flex-col items-center">
              <p className="text-body-2 sm:text-body-1 pb-4 sm:pb-6">
                Reviews - {userInfo.reviewCount}
              </p>
              <div className="flex flex-row flex-wrap justify-center gap-4 sm:gap-8">
                {userInfo.reviews.map((review)=><ReviewCard review={review} forUser />)}
              </div>
            </div>}
          </div>
        </div>}
      </div>
    </>
  );
}
