import Header from "../components/Header";
import Footer from "../components/Footer";
import React, { useEffect, useState } from "react";
import { getPrivacyAndPolicy } from "../services/api";

const PrivacyAndPolicy = () => {
  const [modal, setModal] = useState(false);
  const [systemSettings, setSystemSettings] = useState({});
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('policy'); // Active tab state

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getPrivacyAndPolicy();
        console.log("incoming data", data);
        setSystemSettings(data); 
      } catch (err) {
        setError("Failed to load system settings.");
      }
    }
    fetchData();
  }, []);

  // Function to render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'policy':
        return (
          <>
            {/* <h1 className="text-gold font-bold mb-4">Privacy and Policy</h1> */}
            <p className="text-justify px-10" dangerouslySetInnerHTML={{ __html: systemSettings.privacyPolicy || '' }} />
          </>
        );
      case 'terms':
        return (
          <>
            {/* <h1 className="text-gold font-bold mt-8 mb-4">Terms and Conditions</h1> */}
            <p className="text-justify px-10" dangerouslySetInnerHTML={{ __html: systemSettings.termsAndConditions || '' }} />
          </>
        );
      case 'eula':
        return (
          <>
            {/* <h1 className="text-gold font-bold mt-8 mb-4">End User License Agreement (EULA)</h1> */}
            <p className="text-justify px-10" dangerouslySetInnerHTML={{ __html: systemSettings.endUserLicenseAgreement || '' }} />
          </>
        );
      case 'author':
        return (
          <>
            {/* <h1 className="text-gold font-bold mt-8 mb-4">Author Service Agreement</h1> */}
            <p className="text-justify px-10" dangerouslySetInnerHTML={{ __html: systemSettings.authorServiceAgreement || '' }} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative min-h-screen bg-slate-900 text-white flex flex-col">
      {/* Header */}
      <Header setModal={setModal} modal={modal} />

      {/* Add padding-top to avoid overlap */}
      <div className="flex justify-center my-10 pt-16"> {/* Added pt-16 for padding-top */}
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'policy' ? 'bg-gold' : 'bg-gray-700'} text-white rounded`}
          onClick={() => setActiveTab('policy')}
        >
          Privacy & Policy
        </button>
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'terms' ? 'bg-gold' : 'bg-gray-700'} text-white rounded`}
          onClick={() => setActiveTab('terms')}
        >
          Terms & Conditions
        </button>
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'eula' ? 'bg-gold' : 'bg-gray-700'} text-white rounded`}
          onClick={() => setActiveTab('eula')}
        >
          EULA
        </button>
        <button
          className={`px-4 py-2 mx-2 ${activeTab === 'author' ? 'bg-gold' : 'bg-gray-700'} text-white rounded`}
          onClick={() => setActiveTab('author')}
        >
          Author Service Agreement
        </button>
      </div>

      {/* Content */}
      <div className="flex-grow flex flex-col justify-start items-center text-left px-12 py-6">
        {error ? (
          <p>{error}</p>
        ) : (
          renderContent()
        )}
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default PrivacyAndPolicy;
