import { useState, useEffect } from "react";

function Header({ modal, setModal }) {
  const Links = {
    Download: "#footer",
    Home: '/',
  };

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState("");

    useEffect(() => {
      let lastScrollY = document.documentElement.scrollTop;

      const updateScrollDirection = () => {
        const scrollY = document.documentElement.scrollTop;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection);
      return () => {
        window.removeEventListener("scroll", updateScrollDirection);
      };
    }, [scrollDirection]);

    return scrollDirection;
  }

  const scrollDirection = useScrollDirection();

  // Function to get the download link based on device type
  const getDownloadLink = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      return "https://play.google.com/store/apps/details?id=app.moniro.talk";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      return "https://apps.apple.com/us/app/moniro/id1668817182";
    } else {
      // For Desktop or any other device
      return "https://apps.apple.com/us/app/moniro/id1668817182"; // or Android link if preferred
    }
  };

  return (
    <div
      className={`fixed ${scrollDirection === "down" ? "-top-16 sm:-top-20 " : "top-0"
        } transition-all duration-500 px-2 sm:px-8 py-1 sm:py-2 z-30 flex w-full text-body-1 sm:text-title-2 2xl:text-title-1 font-body font-medium text-white justify-between bg-gradient2/60 bg-opacity-85 backdrop-blur items-center shadow-md`}
    >
      <div className="flex flex-col gap-4 sm:flex-row sm:gap-8">
        <div className="flex flex-row gap-4">
          <a className="py-2 px-4" href={Links.Home}>
            <div className="flex flex-row gap-1 sm:gap-2 lg:gap-3">
              <div className=" w-8 h-7 sm:w-10 sm:h-9 bg-center bg-contain bg-no-repeat bg-[url('./assets/images/logo.svg')]"></div>
              {/* Moniro */}
            </div>
          </a>
        </div>
      </div>
      <button className="w-fit px-4 py-2 bg-orange2 hover:bg-yellow-200 focus:outline-none rounded-lg text-body-2 sm:text-lg 2xl:text-title-2">
        <a onClick={() => {
          window.open(getDownloadLink(), '_blank'); // Open the appropriate link in a new tab
          setModal(!modal);
        }}>
          <p className="bg-gradient-to-r from-gradient1 to-gradient2  bg-clip-text text-transparent">
            Download Now
          </p>
        </a>
      </button>
    </div>
  );
}

export default Header;
