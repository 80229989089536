import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const PREFIX = 'Faq';
const classes = {
  root: `${PREFIX}-root`,
  accordion: `${PREFIX}-accordion`,
  icon: `${PREFIX}-icon`,
  header: `${PREFIX}-header`,
  container: `${PREFIX}-container`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: '#0D1B2A', 
    color: '#fff', 
    padding: theme.spacing(2), 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '50px'
  },
  [`& .${classes.container}`]: {
    maxWidth: '150px', 
    width: '80%',
    padding: theme.spacing(4, 2),
  },
  [`& .${classes.header}`]: {
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.accordion}`]: {
    backgroundColor: 'rgba(5, 36, 51, 1)',
    color: '#fff',
    margin: '5px 150px' ,
    marginBottom: theme.spacing(2),
    borderRadius: '10px',
    '& .MuiAccordionSummary-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '@media (max-width: 600px)': {
      margin: '5px 10px' ,
      
    },
  },
  [`& .${classes.icon}`]: {
    color: '#fff',
  },
}));

const FAQs: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Root className={classes.root}>
      <Container>
        <center>
        <Typography style={{fontFamily: 'Raleway', fontSize: '48px', fontWeight: 600 }} variant="h4" gutterBottom>
          Have Questions?
        </Typography>
        <Typography variant="h6"  style={{fontFamily: 'Poppins', fontSize: '34px',  fontWeight: 500 }} gutterBottom>
          Here are the FAQs
        </Typography>
        </center>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel1' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Is Moniro App Free?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Moniro offers several membership levels including a Free tier, which allows up to 2 hours of speaking and 10 hours of listening time. For unlimited access and additional features, we have premium paid options.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel2' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Where can I get the app?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            You can download Moniro from the App Store for iOS devices and Google Play for Android devices.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel3' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography>Can I use Moniro without downloading the app?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            While you need to download the Moniro app to access its full range of features and interactive functionalities, you can watch public live streams online without the app if you have the link. To engage more deeply, including participating in discussions and accessing personalized features, downloading the app is required.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel5' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography>What is Moniro, and how does it connect book lovers?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Moniro is a digital platform that allows book enthusiasts to join book clubs, discuss books, and share their reading experiences with others.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel6' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography>How do I create and join book clubs on Moniro?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Users can create their own book clubs or join existing ones by browsing the app and finding clubs that match their interests.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel7' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel7a-content"
            id="panel4a-header"
          >
            <Typography>Are book clubs on Moniro public or private?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Book clubs on Moniro can be either public or private, allowing users to create and join clubs that match their preferences. Public clubs are open for anyone to join, while private clubs require an invitation or approval to participate.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel11' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel11a-content"
            id="panel7a-header"
          >
            <Typography>How do I organize my personal reading list on Moniro?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Moniro offers tools to help users create and manage personal reading lists, keeping track of books they want to read, are currently reading, or have already read.

            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel8' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography>How can I share my book reviews and ratings?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Post reviews and ratings for books you’ve read to help other users discover new titles and engage in discussions.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel9' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel9a-content"
            id="panel4a-header"
          >
            <Typography>Where can I find support or provide feedback about the app?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Contact support through the app or website, and submit feedback to help improve Moniro.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          className={classes.accordion}
          expanded={expanded === 'panel10'}
          onChange={handleChange('panel10')}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'panel10' ? (
                <RemoveIcon className={classes.icon} />
              ) : (
                <AddIcon className={classes.icon} />
              )
            }
            aria-controls="panel10a-content"
            id="panel4a-header"
          >
            <Typography>How do I import and connect  Moniro with other book platforms like Goodreads?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            We are currently working on features that will allow you to import your books from other platforms such as Goodreads. If there is a specific platform you are interested in connecting with Moniro, please send us an email and let us know. 

            </Typography>
          </AccordionDetails>
        </Accordion>
      </Container>

    </Root>
    
  );
};

export default FAQs;
