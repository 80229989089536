import { get, getDatabase, onValue, ref } from "firebase/database";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import tinycolor from "tinycolor2";
function TalkCard({ talk, book, big, onClick = () => { } }: any) {
  const [day, date,year, time] = talk.dateTime.split(",");
  const color = tinycolor(talk.background).isDark();
  // console.log(date);
  // console.log(day);
  // console.log(time);

  const [talkAction, setTalkAction] = React.useState("");
  const [admins, setAdmins] = React.useState([]);
  const [participants, setParticipants] = React.useState([]);
  const [speakers, setSpeakers] = React.useState([]);
  const [guests, setGuests] = React.useState([]);
  const navigate = useNavigate();
  React.useEffect(() => {
    const liveSessions=talk.bookSessions.filter(
      (session: { sessionStatus: string }) => session.sessionStatus === "LIVE" || session.sessionStatus === "INSTANT"
    );
    if (
      liveSessions.length > 0
    ) {
      const liveSession=liveSessions[0];
      setTalkAction(
        liveSession._id
      );
      const database = getDatabase();
    const userListRef = ref(database, 'rtc/channel/' + liveSession._id + '/userlist');
    onValue(userListRef, async (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        // navigate(`/booktalk/${talk._id}`);
        setTalkAction('');
      }
      else {
        const ladmins = data.admin ? Object.keys(data.admin) : [];
        const lparticipants = data.participant ? Object.keys(data.participant) : [];
        const lspeakers = data.speaker ? Object.keys(data.speaker) : [];
        const lguests = data.guest ? Object.keys(data.guest) : [];
        setTimeout(async () => {
          const theAdmins = await Promise.all(ladmins.map((admin) => get(ref(database, 'rtc/channel/' + liveSession._id + '/userdata/' + admin)).then((snapshot) => snapshot.val())));
          setAdmins(theAdmins);
          const theParticipants = await Promise.all(lparticipants.map((participant) => get(ref(database, 'rtc/channel/' + liveSession._id + '/userdata/' + participant)).then((snapshot) => snapshot.val())));
          setParticipants(theParticipants);
          const theSpeakers = await Promise.all(lspeakers.map((speaker) => get(ref(database, 'rtc/channel/' + liveSession._id + '/userdata/' + speaker)).then((snapshot) => snapshot.val())));
          setSpeakers(theSpeakers);
          setGuests(lguests);
        }, 2000);
      }
    });
    }
  }, []);
  if (big) {
    return (
      <div onClick={()=>{navigate(`/booktalk/${talk._id}`);}} className="w-72 h-fit sm:w-96 overflow-clip drop-shadow-card p-4 pb-2 sm:pb-4 rounded-2xl" style={{ backgroundColor: talk.background }}>
        <div className="items-center flex flex-row aligh-start  gap-4 mb-2 sm:mb-4">
          <div
            onClick={(e)=>{e.stopPropagation(); navigate(`/book/${book._id}`);}}
          ><img
              src={book.imageUrl}
              className="w-20 h-28 sm:w-28 sm:h-40 object-cover bg-center rounded-lg drop-shadow-book hover:cursor-pointer"
            /></div>
          <div className="my-2">
            <p className={"font-body text-body-2 text-ellipsis line-clamp-1 max-w-40 "+(color?"text-white":"text-slate-900")}>
              {book.author}
            </p>
            <h1 className={"font-body sm:font-title uppercase text-body-1 sm:text-title-2 font-medium sm:font-bold h-16 sm:h-20 text-ellipsis line-clamp-2 max-w-40 sm:max-w-52 mb-3 sm:mb-0"+(color?"text-white":"text-slate-900")}>
              {book.title}
            </h1>
            <div className="sm:hidden flex w-full justify-between">
              <div>
                <p className={"font-body text-small-1 mb-0.5 "+(color?"text-white":"text-slate-900")}>{date}</p>
                <p className={"font-body text-small-1 "+(color?"text-white":"text-slate-900")}>{time}</p>
              </div>
              <div className="grid text-right w-fit place-items-end">
                <div className="flex gap-0.5">
                  <p className={"font-body text-small-1 mb-0.5 "+(color?"text-white":"text-slate-900")}>
                    {talkAction? (admins.length+speakers.length) :talk.speakers}
                  </p>
                  <span className="icon-[solar--user-speak-rounded-bold] w-4 h-4"></span>
                </div>
                <div className="flex gap-0.5">
                  <p className={"font-body text-small-1 mb-0.5 "+(color?"text-white":"text-slate-900")}>
                    {talkAction? (participants.length+guests.length) :talk.listeners}
                  </p>
                  <span className="icon-[mdi--ear-hearing] w-4 h-4"></span>
                </div>
              </div>
            </div>
            <div className="hidden sm:flex w-fit items-center gap-2 mb-4 rounded-md">
              <p className={"font-body text-body-2 "+(color?"text-white":"text-slate-900")}>{date}</p>
              <div className="w-1.5 h-1.5 bg-gray-500 rounded-full"></div>
              <p className={"font-body text-body-2 "+(color?"text-white":"text-slate-900")}>{time}</p>
              <div className="w-1.5 h-1.5 bg-gray-500 rounded-full"></div>
              <p className={"font-body text-body-2 "+(color?"text-white":"text-slate-900")}>{year}</p>
            </div>
            <div className="hidden sm:flex w-full gap-4 text-gray-700">
              <div className="grid justify-items-center px-3 py-1 pt-1 bg-orange-200 rounded-lg">
                <div className="flex items-center gap-1">
                  <p className="font-body text-body-1 font-medium">
                    {talkAction? (admins.length+speakers.length) :talk.speakers}
                  </p>
                  <span className="icon-[solar--user-speak-rounded-bold] w-4 h-4"></span>
                </div>
                <p className="font-body text-small-1">Speaking</p>
              </div>
              <div className="grid justify-items-center px-3 py-1 pt-1 bg-orange-200 rounded-lg">
                <div className="flex items-center gap-1">
                  <p className="font-body text-body-1 font-medium">
                    {talkAction? (participants.length+guests.length) :talk.listeners}
                  </p>
                  <span className="icon-[mdi--ear-hearing] w-4 h-4"></span>
                </div>
                <p className="font-body text-small-1">Listening</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-fill">
          <button
            onClick={(e)=>{
              e.stopPropagation();
              if(talkAction)
                navigate(
                  "/booktalk/" + talk._id + "/live/" + talkAction
                )
                else onClick();
              }
            }
            className="h-8 sm:h-10 w-full m-auto bg-gradient-to-r from-gradient1 to-gradient2 hover:bg-gradient-to-r hover:from-gray-800 hover:to-blue-600  focus:outline-none text-white text-body-2 sm:text-body-1 font-medium sm:font-normal rounded-full"
          >
            {talkAction ? "Listen" : "Join"}
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="w-44 h-24 sm:w-64 sm:h-36 flex flex-row items-center aligh-start drop-shadow-card p-2 sm:p-3 gap-3 rounded-xl" style={{ backgroundColor: talk.background }}>
      <div
        className="w-11 sm:w-16 h-16 sm:h-24 bg-cover bg-center rounded-lg drop-shadow-book"
        style={{ backgroundImage: `url(${book.imageUrl})` }}
      ></div>
      <div className="my-1">
        <p className={"font-body text-small-1 sm:text-body-2 max-w-28 text-ellipsis line-clamp-1 "+(color?"text-white":"text-slate-900")}>
          {book.author}
        </p>
        <h1 className={"font-body text-body-2 h-12 sm:h-16 sm:text-body-1 sm:font-medium text-ellipsis line-clamp-2 max-w-28 sm:max-w-44 mb-1 sm:mb-2"+(color?"text-white":"text-slate-900")}>
          {book.title}
        </h1>
        <div className="hidden sm:flex w-fit items-center bg-gray-500 gap-2 px-2 rounded-md">
          <p className="font-body text-body-2 text-white">{date}</p>
          <div className="w-1 h-1 bg-white rounded-full"></div>
          <p className="font-body text-body-2 text-white">{time}</p>
        </div>
        <p className={"sm:hidden font-body text-small-2 "+(color?"text-white":"text-slate-900")}>{date}</p>
      </div>
    </div>
  );
}

export default TalkCard;
