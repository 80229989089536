import { Routes, Route, Outlet, Link } from "react-router-dom";
// import logo from "./assets/images/logo.svg";
import "./App.css";
import TheHome from "./pages/homepage";
import HomapageNew from "./pages/homepagenew";
import Podcast from "./pages/podcast";
import BookInfo from "./pages/bookinfo";
import BookClub from "./pages/bookclub";
import UserPage from "./pages/user";
import Bookshelf from "./pages/bookshelf";
import AgoraP from "./pages/agora";
import AuthorBookTips from "./pages/authors";
import FamouesPersonDetails from "./pages/FamousPersonDetail";
import NotFound from "./pages/NotFound";
import PrivacyAndPolicy from "./pages/privacyandpolicy";
function App() {
  return (
    <Routes>
      <Route path="/" element={<HomapageNew />} />
      <Route
        path="/:userName/booktalk/:podcastId/:sharerId"
        element={<Podcast />}
      />
     
      <Route path="/booktalk/:podcastId" element={<Podcast />} />
      <Route path="/booktalk/:podcastId/:sharerId" element={<Podcast />} />
      <Route path="/booktalk/:podcastId/live/:sessionId" element={<AgoraP />} />
      <Route path="/book/:bookId" element={<BookInfo />} />
      <Route path="/:userName/book/:bookId" element={<BookInfo />} />
      <Route path="/bookclub/:bookClubId" element={<BookClub />} />
      <Route path="/:userName" element={<UserPage />} />
      <Route path="/:userName/bookshelf" element={<Bookshelf />} />
      <Route path="/:userName/bookclub/:bookClubId" element={<BookClub />} />
      <Route path="/:userName/bookClub/:bookClubId" element={<BookClub />} />
      <Route path="/web/recommendations" element={<AuthorBookTips/>}/>
      <Route path="/web/booktips" element={<AuthorBookTips/>}/>
      <Route path="/web/:id/:fname" element ={<FamouesPersonDetails />} />
      <Route path="/privacyandpolicy" element ={<PrivacyAndPolicy />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
}

export default App;
