function Book({ book, big }: any) {
  const fullStars = Math.floor(book.avgRating ?? 0);
  const halfAStar = (book.avgRating ?? 0) - fullStars;

  const starsArray = Array(fullStars).fill(1);

  if (big) {
    return (
      <div className="w-fit h-fit flex flex-col items-center gap-3 p-4 sm:p-6 bg-white/30 backdrop-blur-sm rounded-lg sm:rounded-xl">
        <div
          className="w-24 h-36 sm:w-32 sm:h-48 bg-cover bg-center rounded-lg drop-shadow-book"
          style={{ backgroundImage: `url(${book.imageUrl})` }}
        ></div>
        <div className="w-28 sm:w-36 items-start flex gap-1 sm:gap-1.5">
          {starsArray.map((v,i) => (
            <span key={i} className="icon-[fluent--star-24-filled] bg-yellow-400 drop-shadow-card w-5 h-5 sm:w-6 sm:h-6"></span>
          ))}
          {halfAStar > 0 && (
            <span className="icon-[fluent--star-half-24-filled] bg-yellow-400 drop-shadow-card w-5 h-5 sm:w-6 sm:h-6"></span>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="w-fit h-fit p-3 sm:p-4 bg-white/30 backdrop-blur-sm rounded-lg sm:rounded-xl">
      <div
        className="w-16 h-24 sm:w-24 sm:h-36 bg-cover bg-center rounded-lg drop-shadow-book"
        style={{ backgroundImage: `url(${book.imageUrl})` }}
      ></div>
    </div>
  );
}
export default Book;
