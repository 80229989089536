import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSpring, animated } from '@react-spring/web';  // Importing react-spring
import homeicon from '../../assets/images/home.png';
import booktalkicon from '../../assets/images/mic_double.png';
import clubicon from '../../assets/images/groups.png';

const StyledIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
}));

const IconImage = styled('img')(({ theme }) => ({
  height: '30px',
  width: '30px',
  objectFit: 'contain',
}));

const Experience = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:1150px)');

  // Increased timing for the first Grid item
  const firstGridAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 200, friction: 20, duration: 1000 }, // Duration increased to 1000ms
    delay: 300, // Increased delay
  });

  // Increased timing for the second Grid item
  const secondGridAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 200, friction: 20, duration: 1000 }, // Duration increased to 1000ms
    delay: 1000, // Increased delay for second animation
  });

  // Increased timing for the third Grid item
  const thirdGridAnimation = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { tension: 200, friction: 20, duration: 1000 }, // Duration increased to 1000ms
    delay: 1700, // Increased delay for third animation
  });

  return (
    <Box
      alignItems={'center'}
      height={'auto'}
      width={'100vw'}
      textAlign="center"
      style={{
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        color: '#FFFFFF',
        background: 'rgba(5, 36, 51, 1)',
      }}
      p={2}
    >
      <Typography variant="h4" sx={{ fontFamily: 'Raleway', fontSize: '48px', fontWeight: '600', marginBottom: '50px' }}>
        The Moniro Experience
      </Typography>
      <Grid container spacing={4} direction={isMobile ? 'column' : 'row'} alignItems="flex-start" justifyContent="center">
        
        {/* First Grid Item */}
        <Grid item xs={12} md={4}>
          <animated.div style={firstGridAnimation}>
            <StyledIcon>
              <IconImage src={homeicon} alt="Personalized Icon" />
            </StyledIcon>
            <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontSize: '30px' }}>
              Personalized
            </Typography>
            <Typography variant="body1" sx={{
              marginTop: theme.spacing(1),
              marginLeft: theme.spacing(2),
              fontFamily: 'Poppins',
              fontSize: '18px',
              textAlign: 'start',
              lineHeight: 1.5,
              letterSpacing: '0.1px'
            }}>
              Enjoy a tailored reading journey with Moniro, where your recommendations evolve from your past reads, preferred genres, favorite authors, and your participation in engaging book talks.
            </Typography>
          </animated.div>
        </Grid>

        {/* Second Grid Item */}
        <Grid item xs={12} md={4}>
          <animated.div style={secondGridAnimation}>
            <StyledIcon>
              <IconImage src={booktalkicon} alt="User Friendly Icon" />
            </StyledIcon>
            <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontSize: '30px' }}>
              Book Talks
            </Typography>
            <Typography variant="body1" sx={{
              marginTop: theme.spacing(1),
              marginLeft: theme.spacing(2),
              fontFamily: 'Poppins',
              fontSize: '18px',
              textAlign: 'start',
              lineHeight: 1.5,
              letterSpacing: '0.1px'
            }}>
              Navigate Moniro effortlessly, thanks to our user-friendly design. Dive into enriching book talks and master the art of literature discussion in no time.
            </Typography>
          </animated.div>
        </Grid>

        {/* Third Grid Item */}
        <Grid item xs={12} md={4}>
          <animated.div style={thirdGridAnimation}>
            <StyledIcon>
              <IconImage src={clubicon} alt="Community Driven Icon" />
            </StyledIcon>
            <Typography variant="h6" sx={{ fontFamily: 'Poppins', fontSize: '30px' }}>
              Community
            </Typography>
            <Typography variant="body1" sx={{
              marginTop: theme.spacing(1),
              marginLeft: theme.spacing(2),
              fontFamily: 'Poppins',
              fontSize: '18px',
              textAlign: 'start',
              lineHeight: 1.5,
              letterSpacing: '0.1px'
            }}>
              Join a global network of passionate readers on Moniro. Discover like-minded friends, create or join dynamic book clubs, and engage in thought-provoking discussions that go beyond the page.
            </Typography>
          </animated.div>
        </Grid>

      </Grid>
    </Box>
  );
};

export default Experience;
